"use client";
import { FC, useEffect, useState } from "react";
import IconCard from "../common/icon-card";
import { Button } from "@/components/bootstrap";
import ShowAddressModal from "../common/show-address-modal";
import {
  AMAZONE_MEDIA_URL,
  ThumbImageRatio,
  defaultproduct,
} from "@/utils/constant";
import { useRouter } from "@/navigation";
import Skeleton from "react-loading-skeleton";
import { useTranslations } from "next-intl";

const MarketplaceCard: FC<any> = ({ supplierProduct }) => {
  const MarketplaceText = useTranslations("marketplaceText")
  const router = useRouter();
  const [modalShow, setModalShow] = useState<boolean>(false);
  const handleIconClick = () => {
    setModalShow(true);
  };
  const [loading, setLoading] = useState(true);
useEffect(() => {
  if (supplierProduct) {
    setLoading(false);
  }
}, [supplierProduct]);
  const handleData = (slug: any) => {
    router.replace(`/item-detail/${slug}`);
  };
  return (
    <>
     {!loading ?( <div className="bg-white py-4 rounded-4">
        <div className="d-md-flex justify-content-between align-items-center mb-3 px-4">
          <IconCard
            icon={supplierProduct?.business_logo_path}
            title={supplierProduct?.business_name}
            time={supplierProduct?.industry_name}
            id={supplierProduct?.user_id}
            page={"marketplace-page"}
          />
          <div className="mt-3 mt-md-0">
            <Button
             type="button"
              className="text-black bg-white border-0 rounded-5 shadow mx-2 mx-md-3 px-4"
              size="lg"
              onClick={handleIconClick}
            >
              {MarketplaceText("call")}
            </Button>
            <Button
             type="button"
              className="text-black bg-white border-0 rounded-5 shadow mx-2 mx-md-3 px-4"
              size="lg"
              onClick={handleIconClick}
            >
            {MarketplaceText("email")}
            </Button>
          </div>
        </div>
        <div className="d-flex justify-content-center gap-4 px-4">
          <div style={{ width: "400px" }}>
            <p className="text-black fw-normal">
              {supplierProduct?.business_info}
            </p>
          </div>
          {supplierProduct?.products?.data.slice(0, 3).map((item: any) => (
            <div
              className="item text-center bg-white market-card custom-marketplace-css cursor-pointer custom-theme-shadow"
              key={item.id}
            >
              <img
                loading="lazy"
                alt="image"
                className="card__img card-img-cover"
                src={
                  item.media_path
                    ? AMAZONE_MEDIA_URL +
                      "User/" +
                      item.user_id +
                      "/Product/" +
                      item.id +
                      "/" +
                      ThumbImageRatio +
                      "/" +
                      item.media_path
                    : defaultproduct
                }
                width="100%"
                height="100%"
                onClick={() => handleData(item.slug)}
                onError={(e) => {
                  e.currentTarget.src = defaultproduct; // Fallback to default image
                }}
              />
            </div>
          ))}
        </div>
      </div>):(
         <div className="bg-white py-4 rounded-4">
         <div className="d-md-flex justify-content-between align-items-center mb-3 px-4">
         <div className="mt-3 mt-md-0 d-flex gap-3">
          <Skeleton height={40} width={40} borderRadius={50}/>
          <Skeleton height={10} width={190} count={2} />
          </div>
           <div className="mt-3 mt-md-0 d-flex gap-3">
           <Skeleton height={30} width={90} borderRadius={50}/>
           <Skeleton height={30} width={90} borderRadius={50}/>
           </div>
         </div>
         <div className="d-flex justify-content-center gap-4 px-4">
           <div style={{ width: "400px" }}>
             <p className="text-black fw-normal">
             <Skeleton count={10} height={10}/>
             </p>
           </div>
           <Skeleton height={200} width={240} borderRadius={10}/>
           <Skeleton height={200} width={240} borderRadius={10}/>
           <Skeleton height={200} width={240} borderRadius={10}/>
         </div>
       </div>
      )}
      <ShowAddressModal
        type="supplier"
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={supplierProduct}
      />
    </>
  );
};

export default MarketplaceCard;
