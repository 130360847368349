"use client";
import { IconCardProps } from "@/utils/types";
import React from "react";
import { Button } from "@/components/bootstrap";
import { AMAZONE_MEDIA_URL } from "@/utils/constant";
import { default_supplier_logo } from "@/utils/CountryCodes";
import { useRouter } from "@/navigation";
import { useSettingsContext } from "@/context";

export default function ProdcuctIconCard({
  icon,
  title,
  time,
  handleModalShow,
  supplier_id,
  seller_slug,
  id
}: IconCardProps) {
  const router = useRouter();
  const {onUpdate} = useSettingsContext();
  return (
    <div className="product_detail_icon icon-card-wrapper mx-md-3 bg-white align-items-center d-flex justify-content-between">
      <div className="d-flex align-items-center justify-content-between ">
        <img
          alt="Business logo"
          className="cursor-pointer"
          src={
            icon
              ? `${AMAZONE_MEDIA_URL}User/${supplier_id}/BusinessLogo/${icon}`
              : default_supplier_logo
          }
          height={65}
          width={65}
          onClick={() => router.push(`/business-network/supplier-profile/${seller_slug}`)}
        />
        <p className="fs-6 fw-light px-4 mt-2">
          {title} <br />
          <span className="fs-6 fw-light">{time}</span>
        </p>
      </div>
      <Button className="btn_secondary get-started-btn" onClick={()=>{
        onUpdate("redirectionUrl", {
          path: "get a quote",
          id: id || ""
        });
        handleModalShow}}>
        Get A Quote
      </Button>
    </div>
  );
}
