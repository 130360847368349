"use client";
import { useState, useEffect } from "react";
import {
  Nav,
  TabContainer,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "@/components/bootstrap";
import {
  fetchCoordinates,
  fetchSupplierNetworkData,
  fetchExpertNetworkData,
  fetchCategoryData,
  fetchPopularBuyers,
  fetchPopularSuppliers,
  fetchTopBrands,
  fetchBuyerNetworkData,
} from "@/utils/apiServices";
import {
  SupplierNetworkPayload,
  ExpertNetworkPayload,
  CategoryData,
  CategoryPayload,
  popularSupplierBuyer,
  topBrands,
} from "@/utils/types";
import { USER_TYPE_BUYER, USER_TYPE_SUPPLIER } from "@/utils/constant";
import DiscoverAllLayout from "./discover-all-layout";
import {
  SupplierBuyerNetworkCard,
  ExpertNetworkCard,
  NetworkCard,
  BussinessMapView,
  BussinessNetworkSidebar,
  BussinessCombineView,
  ExpertCombineView,
  ExpertCommonCard,
  ScrollPagination,
  ExpertSuccessStories,
} from "../common";
import { PopularSupplierSection } from "@/components/widgets";
import Lottie from "lottie-react";
import loaderData from "../../utils/animationJson/loader.json";
import { useSettingsContext } from "@/context";
import { useTranslations } from "next-intl";
import { useRouter } from "@/navigation";

function BussinessNetworkTabs({ tabsData, bannerData, currentItem }: any) {
  const router = useRouter();
  const { onUpdate, searchTerm, businessCategory, currentTab } = useSettingsContext();
  const [activeTab, setActiveTab] = useState("discover-all");
  const [networkCardData, setNetworkCardData] = useState({
    heading: "",
    subheading: "",
    bgImage: "",
  });
  const [supplierData, setSupplierData] = useState<any>([]);
  const [buyerData, setBuyerData] = useState<any>([]);
  const [expertData, setExpertData] = useState<any>([]);
  const [supplierSearchQuery, setSupplierSearchQuery] = useState("");
  const [rating, setRating] = useState(0);
  const [isFeatured, setIsFeatured] = useState(false);
  const [isBookMakared, setIsBookMarked] = useState(false);
  const [buyerSearchQuery, setBuyerSearchQuery] = useState("");
  const [expertSearchQuery, setExpertSearchQuery] = useState("");
  const [coords, setCoords] = useState({ latitude: 0, longitude: 0 });
  const [categoryData, setCategoryData] = useState<CategoryData[]>([]);
  const [popularSupplier, setPopularSupplier] = useState<
    popularSupplierBuyer[]
  >([]);
  const [popularBuyer, setPopularBuyer] = useState<popularSupplierBuyer[]>([]);
  const [supplierSelectedCategories, setSupplierSelectedCategories] = useState<
    number[]
  >([]);
  const [buyerSelectedCategories, setBuyerSelectedCategories] = useState<
    number[]
  >([]);
  const [expertSelectedCategories, setExpertSelectedCategories] = useState<
    number[]
  >([]);
  const [supplierIndustryId, setSupplierIndustryId] = useState<number>(0);
  const [buyerIndustryId, setBuyerIndustryId] = useState<number>(0);
  const [expertIndustryId, setExpertIndustryId] = useState<number>(0);
  const [isSupplierLoading, setIsSupplierLoading] = useState(true);
  const [isLoadSupplier, setIsLoadSupplier] = useState<boolean>(true);
  const [supplierTotalRecords, setSupplierTotalRecords] = useState<number>(0);
  const [supplierRecords, setSupplierRecords] = useState<any>({});
  const [supplierFilterOption, setSupplierFilterOption] = useState({
    record_count: 0,
    limit: 15,
  });
  const [isBuyerLoading, setIsBuyerLoading] = useState(true);
  const [isLoadBuyer, setIsLoadBuyer] = useState<boolean>(true);
  const [buyerTotalRecords, setBuyerTotalRecords] = useState<number>(0);
  const [buyerFilterOption, setBuyerFilterOption] = useState({
    record_count: 0,
    limit: 15,
  });
  const [isExpertLoading, setIsExpertLoading] = useState(true);
  const [isLoadExpert, setIsLoadExpert] = useState<boolean>(true);
  const [expertTotalRecords, setExpertTotalRecords] = useState<number>(0);
  const [expertFilterOption, setExpertFilterOption] = useState({
    record_count: 0,
    limit: 15,
  });
  const [topBrands, setTopBrands] = useState<topBrands[]>([]);
  let paginator = ScrollPagination();
  const networkTabText = useTranslations("networkTabText")

  useEffect(() => {
    if (tabsData.length > 0) {
      setNetworkCardData({
        heading: tabsData[0]?.headline || "",
        subheading: tabsData[0]?.content || "",
        bgImage: tabsData[0]?.theme.backgroungImgUrl || "",
      });
    }
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const coordsData = await fetchCoordinates();
        setCoords(coordsData);
      } catch (error) {
        console.error("Error fetching Coordinates data:", error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchTopBrandsData() {
      try {
        let data = {
          limit: 10,
          recordCount: 1,
        };
        const brandData = await fetchTopBrands(data);
        setTopBrands(brandData);
      } catch (error) {
        console.error("Error fetching Coordinates data:", error);
      }
    }
    fetchTopBrandsData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const popularBuyers = await fetchPopularBuyers();
        setPopularBuyer(popularBuyers);
        const popularSuppliers = await fetchPopularSuppliers();
        setPopularSupplier(popularSuppliers);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const categoriesPayload: CategoryPayload = {
        filter: "",
        language_id: 1,
      };
      const categoriesData = await fetchCategoryData(categoriesPayload);
      setCategoryData(categoriesData);
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchSupplierData() {
      const supplierPayload: SupplierNetworkPayload = {
        ...supplierFilterOption,
        latitude: coords.latitude,
        longitude: coords.longitude,
        search: supplierSearchQuery,
        user_type_id: USER_TYPE_SUPPLIER,
        is_featured: isFeatured,
        rating: rating,
        in_watchlist: isBookMakared,
      };

      if (searchTerm !== "" && searchTerm !== undefined) {
        handleTabChange("suppliers");
        supplierPayload.search = searchTerm;
      }
      if (businessCategory != 0) {
        handleTabChange("suppliers");
        setSupplierSelectedCategories([
          ...supplierSelectedCategories,
          businessCategory,
        ]);
        setSupplierIndustryId(businessCategory);
      }

      if (supplierSelectedCategories.length > 0) {
        supplierPayload.industry_ids = supplierSelectedCategories.join(",");
      }

      try {
        const supplier: any = await fetchSupplierNetworkData(supplierPayload);
        if (supplier.status === 200) {
          setSupplierTotalRecords(supplier?.total_records);
          setSupplierData([...supplierData, ...supplier?.data]);
          setSupplierRecords(supplier?.counts);
          setIsLoadSupplier(false);
          setIsSupplierLoading(false);
        } else {
          setIsLoadSupplier(false);
          setIsSupplierLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    }
    if (coords.latitude && coords.longitude) {
      fetchSupplierData();
    }
  }, [
    supplierFilterOption,
    coords,
    supplierSearchQuery,
    supplierIndustryId,
    rating,
    isFeatured,
    isBookMakared,
    businessCategory,
  ]);

  useEffect(() => {
    async function fetchBuyerData() {
      const buyerPayload: SupplierNetworkPayload = {
        ...buyerFilterOption,
        latitude: coords.latitude,
        longitude: coords.longitude,
        search: buyerSearchQuery,
        user_type_id: USER_TYPE_BUYER,
        is_featured: isFeatured,
        rating: rating,
      };
      if (searchTerm !== "" && searchTerm !== undefined) {
        buyerPayload.search = searchTerm;
      }
      if (businessCategory != 0) {
        setBuyerSelectedCategories([
          ...buyerSelectedCategories,
          businessCategory,
        ]);
        setBuyerIndustryId(businessCategory);
      }
      if (buyerSelectedCategories.length > 0) {
        buyerPayload.industry_ids = buyerSelectedCategories.join(",");
      }

      try {
        const buyer = await fetchBuyerNetworkData(buyerPayload);
        if (buyer.status === 200) {
          setBuyerTotalRecords(buyer.total_records);
          setBuyerData([...buyerData, ...buyer?.data]);
          setIsLoadBuyer(false);
          setIsBuyerLoading(false);
        } else {
          setIsLoadBuyer(false);
          setIsBuyerLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    }
    if (coords.latitude && coords.longitude) {
      fetchBuyerData();
    }
  }, [
    buyerFilterOption,
    coords,
    buyerSearchQuery,
    buyerIndustryId,
    rating,
    isFeatured,
    businessCategory,
  ]);

  useEffect(() => {
    async function fetchExpertData() {
      const expertPayload: ExpertNetworkPayload = {
        ...expertFilterOption,
        latitude: coords.latitude,
        longitude: coords.longitude,
        price_end_range: 500,
        price_start_range: 0,
        search: expertSearchQuery,
        is_featured: isFeatured,
        rating: rating,
      };
      if (searchTerm !== "" && searchTerm !== undefined) {
        expertPayload.search = searchTerm;
      }
      if (businessCategory != 0) {
        setExpertSelectedCategories([
          ...expertSelectedCategories,
          businessCategory,
        ]);
        setExpertIndustryId(businessCategory);
      }
      if (expertSelectedCategories.length > 0) {
        expertPayload.industry_ids = expertSelectedCategories.join(",");
      }
      try {
        const expert = await fetchExpertNetworkData(expertPayload);
        if (expert.status === 200) {
          setExpertTotalRecords(expert.total_records);
          setExpertData([...expertData, ...expert?.data]);
          setIsLoadExpert(false);
          setIsExpertLoading(false);
        } else {
          setIsLoadExpert(false);
          setIsExpertLoading(false);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoadExpert(false);
        setIsExpertLoading(false);
      }
    }
    if (coords.latitude && coords.longitude) {
      setIsExpertLoading(true);
      fetchExpertData();
    }
  }, [
    expertFilterOption,
    coords,
    expertSearchQuery,
    expertIndustryId,
    rating,
    isFeatured,
    businessCategory,
  ]);

  const handleTabChange = (selectedTab: string | null) => {
    if (selectedTab) {
      setActiveTab(selectedTab);
      const selectedData = tabsData.find(
        (tab: any) => tab.title === selectedTab
      );
      if (selectedData) {
        setNetworkCardData({
          heading: selectedData.headline || "",
          subheading: selectedData?.content || "",
          bgImage: selectedData?.theme.backgroungImgUrl || "",
        });
      }
    }
  };
  return (
    <>
      <div className="px-md-5 pt-md-5">
        <NetworkCard
          heading={networkCardData.heading}
          subHeading={networkCardData.subheading}
          bgImage={networkCardData.bgImage}
        />
      </div>
      <div className="my-5">
        <TabContainer
          activeKey={activeTab}
          onSelect={handleTabChange}
          id="myTab"
        >
          <Nav
            className="nav-tabs px-md-5 px-3 d-flex flex-wrap justify-content-between"
            id="myTab"
            role="tablist"
          >
            <div className="nav nav-tabs-scroll">
              <NavItem className="nav-item" onClick={()=>{
                router.push("/business-network")
              }} role="presentation">
                <NavLink eventKey="discover-all" className="active">{networkTabText("discover_all")}</NavLink>
              </NavItem>
              <NavItem className="nav-item" onClick={()=>{
                router.push("/business-network/suppliers")
              }} role="presentation">
                <NavLink eventKey="suppliers">{networkTabText("suppliers")}</NavLink>
              </NavItem>
              <NavItem className="nav-item" onClick={()=>{
                router.push("/business-network/buyers")
              }}  role="presentation">
                <NavLink eventKey="buyers">{networkTabText("buyers")}</NavLink>
              </NavItem>
              <NavItem className="nav-item" onClick={()=>{
                router.push("/business-network/experts")
              }}  role="presentation">
                <NavLink eventKey="experts">{networkTabText("experts")}</NavLink>
              </NavItem>
            </div>
          </Nav>
          <TabContent className="tab-content overflow-hidden" id="myTabContent">
            <TabPane eventKey="discover-all">
              <DiscoverAllLayout
                popularSupplier={popularSupplier}
                supplierCategories={categoryData}
                topBrands={topBrands}
                expertData={expertData}
                supplierData={supplierData}
                bannerData={bannerData && bannerData}
                handleTabChange={handleTabChange}
              />
            </TabPane>
            </TabContent>
        </TabContainer>
      </div>
    </>
  );
}

export default BussinessNetworkTabs;
