"use client";
import { HeroSectionItems, ServiceItemProps } from "@/utils/types";
import {
  ProcurementSoftwareMobileIcon,
  MarketplaceMobileIcon,
  NetworkMobileIcon,
} from "@/utils/icons";
import { Link } from "@/navigation";
import NextImage from "./next-image";

type serviceCardProps = {
  item: HeroSectionItems;
  type: string;
};

function MobileServiceCard({ item, type }: serviceCardProps) {
  let iconComponent;
  if (item.title === "Procurement Software") {
    iconComponent = <ProcurementSoftwareMobileIcon />;
  } else if (item.title === "B2B Marketplace") {
    iconComponent = <MarketplaceMobileIcon />;
  } else if (item.title === "Business Network") {
    iconComponent = <NetworkMobileIcon />;
  } else {
    iconComponent = <ProcurementSoftwareMobileIcon />;
  }
  return (
    <Link className="col-4 business-cards-mobile position-relative slide-up-box " 
    href={item?.cta?.redirectionUrl}
    >
      {type === "primary" && (
        <div
          className="primary-content text-center main-mobile-heading m-0 py-sm-3 py-2 "
          style={{ background: item.theme.backgroundColor }}
        >
          <p className="pt-3">
        {/* <NextImage src={item?.imageUrl ?? ""} alt={item?.title} style={{maxWidth:"100%"}} width={item.title === "Business Network" ? 65 : item.title === "B2B Ecommerce Software" ? 43 : 50} height={item.title === "Business Network" ? 60 : item.title === "B2B Ecommerce Software" ? 43 : 50}/> */}
        <img src={item?.imageUrl} alt={item.title} style={{maxWidth:"100%"}} width={`${item.title === "Business Network" ? "65" : item.title === "B2B Ecommerce Software" ? "43" : "50"}`} />
          </p>
          <h3 className="text-center text-white text-14 fw-normal pb-2 service-heading">
            {item.title}
          </h3>width
        </div>
      )}
    </Link>
  );
}

export default MobileServiceCard;
