import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';

type Attributes = {
  [key: string]: string[];
};

interface ItemVariationProps {
  variations: any;
  attributes: string;
  variation_name: string;
  setSelectedVariantId: React.Dispatch<React.SetStateAction<string>>;
}

const ItemVariation = ({
  variations,
  attributes,
  variation_name,
  setSelectedVariantId
}: ItemVariationProps) => {
  const [selectedAttributes, setSelectedAttributes] = useState<any>({});
  const dropdownRefs = useRef<Record<string, HTMLDivElement | null>>({});

  const [isOpenState, setIsOpenState] = useState<{
    isOpen: boolean;
    key: string;
  }>({ isOpen: false, key: '' });

  const parsedAttributes: Attributes = JSON.parse(attributes);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const isOutsideClick = Object.keys(dropdownRefs.current).every((key) => {
        const ref = dropdownRefs.current[key];
        return ref && !ref.contains(event.target as Node);
      });

      if (isOutsideClick) {
        setIsOpenState({ isOpen: false, key: '' });
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!variations || variations.length === 0) return;

    const foundVariation = variations.find(
      (variation:any) => variation.type === variation_name
    );

    const foundVariationParts = foundVariation?.type.split('_');

    const initialSelections: Record<string, string> = {};

    Object.keys(parsedAttributes).forEach((key, index) => {
      const value = parsedAttributes[key];
      initialSelections[key] = foundVariationParts?.[index] || value[0];
    });
    
    setSelectedAttributes(initialSelections);
    handleFindVariation(initialSelections);
  }, [attributes, variations, variation_name]);

  const toggleDropdown = (
    key: string,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();

    setIsOpenState((prevState) => ({
      isOpen: prevState.key === key ? false : true,
      key: prevState.key === key ? '' : key,
    }));
  };

  const handleSelectionChange = (key: string, value: string) => {
    const updatedSelections = {
      ...selectedAttributes,
      [key]: value,
    };
    setIsOpenState(() => ({
      isOpen: false,
      key: '',
    }));
    setSelectedAttributes(updatedSelections);
    handleFindVariation(updatedSelections);
  };

  const handleFindVariation = (updatedSelections: Record<string, string>) => {
    const selectedVariationType = Object.keys(updatedSelections)
      .map((attr) => updatedSelections[attr])
      .filter(Boolean)
      .join('_');

    const foundVariation = variations.find(
      (variation:any) => variation.type === selectedVariationType
    );

    if (foundVariation?.variation_id) {
        setSelectedVariantId(foundVariation.variation_id)
    //   setSelectedVariantId(foundVariation.variation_id);
    //   setSelectedVariantName(selectedVariationType)
    console.log('e',foundVariation.variation_id)
    console.log('e',selectedVariationType)
    } else {
    }
  };

  return (
    <div className='d-flex gap-4 px-3 px-lg-0 mt-3 flex-wrap mb-5'>
      {Object.entries(parsedAttributes).map(([key, value]) => {
        return (
          <div key={key}>
            <p className='px-1 fw-medium text-black mb-0'>{key}</p>
            <div
              className='color-variation'
              ref={(el) => {
                dropdownRefs.current[key] = el;
              }}
            >
              <div>
                <div
                  className='dropdown-button'
                  onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
                    toggleDropdown(key, e)
                  }
                >
                  <span>{selectedAttributes[key]}</span>
                  <span>&#9662;</span>
                </div>
                <motion.div
                  className='dropdown-content'
                  initial={{ height: 0, opacity: 0 }}
                  animate={{
                    height:
                      isOpenState.isOpen && key === isOpenState.key
                        ? 'auto'
                        : 0,
                    opacity:
                      isOpenState.isOpen && key === isOpenState.key ? 1 : 0,
                  }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.3, ease: 'easeOut' }}
                >
                  {Object.entries(value).map(([index, optionValue]) => (
                    <a
                      key={index}
                      className='cursor-pointer'
                      onClick={() => handleSelectionChange(key, optionValue)}
                    >
                      {optionValue}
                    </a>
                  ))}
                </motion.div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ItemVariation;
