"use client";
import { useSettingsContext } from "@/context";
import { CategoryData } from "@/utils/types";
import { useRouter } from "@/navigation";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

type props = {
  supplierCategories: CategoryData[];
  pageType?: string;
};
const NetworkTabSection = ({ supplierCategories, pageType }: props) => {
  const { onUpdate } = useSettingsContext();
  const router = useRouter();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (supplierCategories) {
      setLoading(false);
    }
  }, [supplierCategories]);

  const handleCategory = (id: number) => {
    onUpdate("businessCategory", id);
    if (pageType === "supplier" || pageType === "buyer") {
      router.push("/business-network");
    }
  };
  return (
    <div>
      <ul className="nav">
        {!loading ? supplierCategories &&
          supplierCategories.slice(0, 6).map((item: any) => (
            <li
              className="nav-item border border-dark border-1 tabs-hover-effect rounded-5 mx-3 px-2 my-2"
              key={item.industry_id}
              onClick={() => handleCategory(item.industry_id)}
            >
              <a className="nav-link active" href="#">
                {item.industry_name}
              </a>
            </li>
          ))
        :
        (
          Array.from({ length: 6 }, (_, index) => (
            <li
              className="nav-item border border-dark border-1 tabs-hover-effect rounded-5 mx-3 p-2 my-2"
              key={index}
            >
              <Skeleton width={300} height={20}/>
            </li>
            ))
        )
        }
      </ul>
    </div>
  );
};

export default NetworkTabSection;
