"use client";
import React, { FC, useEffect, useState } from "react";
import { Button } from "@/components/bootstrap";
import Lightbox from "yet-another-react-lightbox";
import { motion } from "framer-motion";
import useLightbox from "@/utils/useLightbox";
import { PhotoItemProps, galleryProps } from "@/utils/types";
import { varTranHover } from "@/utils/helpers";

import "yet-another-react-lightbox/styles.css";
import { useClientMediaQuery } from "@/hooks/use-client-media-query";
import Skeleton from "react-loading-skeleton";
import { isArray } from "tsparticles-engine";
import Reveal from "../common/Reveal";
import NextImage from "../common/next-image";

type props = {
  galleryData: galleryProps;
};
interface SlideImage {
  src: string;
}
const Gallery: FC<props> = ({ galleryData }) => {
  const isMobile = useClientMediaQuery("(max-width: 768px)");
  const [loading, setLoading] = useState(true);
 useEffect(() => {
  if (galleryData) {
    setLoading(false);
  }
}, [galleryData]);
  const slides: SlideImage[] =
  galleryData.items?.map((item:any) => ({
      src: item.mediaUrl,
    })) || [];

  const lightbox = useLightbox(slides);
  return (
    <Reveal width="100%">
    <div className="home-widget container-fluid px-md-3 px-lg-5 py-4">
      <div
        dangerouslySetInnerHTML={{
          __html: (galleryData && galleryData.headline) || "",
        }}
      />
      <div className="px-4 px-lg-5 mb-5">
        <div className="photo-gallery">
          {galleryData.items && galleryData.items.length > 0 && 
            <PhotoItem
              photo={galleryData?.items[0]?.mediaUrl}
              onOpenLightbox={() => {
                if (galleryData && galleryData.items && galleryData.items[0]) {
                  lightbox.onOpen(galleryData.items[0].mediaUrl);
                }
              }}
            />
        
        }

          <div
            className={`${isMobile ? "photo-mobile-gallery" : "photo-gallery"}`}
          >
            {
             galleryData?.items &&
              galleryData?.items.length > 0 &&
              galleryData?.items
                .slice(1, 5)
                .map((item: any) => (
                  <PhotoItem
                    key={item.id}
                    photo={item.mediaUrl}
                    onOpenLightbox={() => lightbox.onOpen(item.mediaUrl)}
                  />
                ))
                }
          </div>
        </div>

        <Lightbox
          index={lightbox.selected}
          slides={slides}
          open={lightbox.open}
          close={lightbox.onClose}
        />
      </div>
      {isMobile && (
        <div className="d-flex justify-content-center">
          <Button
            href={galleryData?.cta?.redirectionUrl}
            type="button"
            variant="secondary"
            className="rounded-5 text-white fs-6 get-started-btn"
            size="lg"
          >
            {galleryData?.cta?.label}
          </Button>
        </div>
      )}
    </div>
    </Reveal>
  );
};

export default Gallery;
// ----------------------------------------------------------------------

function PhotoItem({ photo, onOpenLightbox }: PhotoItemProps) {
  return (
    <>
      <motion.div
        whileHover="hover"
        variants={{
          hover: { opacity: 0.6,scale:(1.1) },
        }}
        transition={varTranHover()}
      >
        <div
          onClick={onOpenLightbox}
          style={{ borderRadius: 18, cursor: "pointer", opacity: 1, overflow: "hidden" }}
        >
          {/* <NextImage
            alt="photo"
            src={photo}
            width={400}
            height={400}
            loading="lazy"
            style={{ width: "100%", height: "auto" }}
          /> */}
           <img
            alt="photo"
            src={photo}
            width={400}
            height={400}
            loading="lazy"
            rel="preload"
            fetchPriority="high"
            style={{ width: "100%", height: "auto" }}
          />
        </div>
      </motion.div>
    </>
  );
}
