"use client";
import React, { useState, useEffect } from "react";
import Select, { ActionMeta, OptionProps } from "react-select";
import {
  CountryCodes,
  CountryCodesProps,
  image_path,
} from "@/utils/CountryCodes";
import { CustomMobileFieldProps } from "@/utils/types";
import { ErrorMessage, Field } from "formik";
import { fetchCountryCode } from "@/utils/apiServices";

const CustomOption: React.FC<OptionProps<CountryCodesProps, false>> = ({
  innerProps,
  label,
}) => {
  const labelContent = { __html: label };

  return (
    <div {...innerProps}>
      <div
        dangerouslySetInnerHTML={labelContent}
        className="custom-label-content p-2"
      ></div>
    </div>
  );
};

const ContactUsCustomMobileField: React.FC<CustomMobileFieldProps> = ({
  handleMobileNumberChange,
  mobileNumber,
  // countryCode,
  setCountryCode,
  setMobileNumberLength
}) => {
  const [selectedCountry, setSelectedCountry] =
    useState<CountryCodesProps | null>(null);
    const [currentCountry, setCurrentCountry] =
    useState<CountryCodesProps | null>(null);
  const [list, setList] = useState<any>([]);

  useEffect(() => {
    fetchCountryCode().then((res) => {
      const formattedList = res.map((country: any) => ({
        value: country.country_id.toString(),
        dialCode: country.dial_code,
        label:
          '<img loading="lazy" className="country-code-image" src="' +
          image_path +
          country?.country_code?.toLowerCase() +
          '.svg" />' +
          country.dial_code,
      }));
      setList(formattedList);
      const filteredCountry = formattedList.filter((item:any) => item.dialCode === "+966");
      setCurrentCountry(filteredCountry[0])
    });
  }, []);
  useEffect(() => {
    
    if (currentCountry) {
      setSelectedCountry(currentCountry);
      setCountryCode({
        country_id: currentCountry.value ?? "",
        dial_code: currentCountry.dialCode ?? "",
      });
      const matchingCountry = CountryCodes.find(
        (country) => country.value === currentCountry.dialCode
      );
      if (matchingCountry?.phoneLength && setMobileNumberLength) {
        setMobileNumberLength(matchingCountry?.phoneLength);
      } else if(setMobileNumberLength) {
        setMobileNumberLength(9);
      }
    } else {
      setSelectedCountry(null);
      setCountryCode({ country_id: "", dial_code: "" });
    }
  },[currentCountry])
  const handleCountryChange = (
    selectedOption: CountryCodesProps | null,
    actionMeta: ActionMeta<CountryCodesProps>
  ) => {
    if (actionMeta.action === "clear") {
      setSelectedCountry(null);
    } else {
      if (selectedOption) {
        setSelectedCountry(selectedOption);
        setCountryCode({
          country_id: selectedOption.value ?? "",
          dial_code: selectedOption.dialCode ?? "",
        });
        const matchingCountry = CountryCodes.find(
          (country) => country.value === selectedOption.dialCode
        );      
        if (matchingCountry?.phoneLength && setMobileNumberLength) {
          setMobileNumberLength(matchingCountry?.phoneLength);
        } else if(setMobileNumberLength) {
          setMobileNumberLength(10);
        }
      } else {
        setSelectedCountry(null);
        setCountryCode({ country_id: "", dial_code: "" });
      }
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="form-group row align-items-center  custom-select-contact rounded-3 d-flex justify-content-between mx-0">
          <div className="col-4  custom-mobile-field-contact">
            {list?.length > 0 && (
              <Select
                options={list}
                value={selectedCountry}
                onChange={handleCountryChange}
                styles={{
                  container: (provided) => ({
                    ...provided,
                    border: "none",
                    backgroundColor: "#fff",
                    padding: "0 0",
                  }),
                  control: (provided) => ({
                    ...provided,
                    border: "none",
                    boxShadow: "none",
                    backgroundColor: "#fff",
                    padding: "0 0",
                    margin: "0",
                  }),
                  indicatorSeparator: () => ({
                    display: "none",
                  }),
                  dropdownIndicator: (provided) => ({
                    ...provided,
                    padding: "0 0px",
                  }),
                  valueContainer: (provided) => ({
                    ...provided,
                    padding: "0 0",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    width: 130,
                    cursor: "pointer",
                    maxHeight: 180,
                    overflowY: "auto",
                  }),
                }}
                components={{ Option: CustomOption }}
                formatOptionLabel={({ label }) => (
                  <div
                    dangerouslySetInnerHTML={{ __html: label }}
                    className="custom-label-content"
                  />
                )}
              />
            )}
          </div>
          <div className="col-8 vertical-line-normal">
            <Field
              type="text"
              id="mobile"
              name="mobile"
              value={mobileNumber}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleMobileNumberChange(e.target.value);
              }}
              placeholder="Mobile No."
              className="form-control custom-white-mobile form-control-lg"
            />
          </div>
        </div>
        <ErrorMessage name="mobile" component="div" className="error-message" />
      </div>
    </div>
  );
};

export default ContactUsCustomMobileField;
