"use client";
import { Button } from "@/components/bootstrap";
import { AMAZONE_MEDIA_URL } from "@/utils/constant";
import { PopularSupplierCardProps } from "@/utils/types";
import { default_supplier_logo } from "@/utils/CountryCodes";
import { useRouter } from "@/navigation";
import { useTranslations } from "next-intl";
import { useSettingsContext } from "@/context";

function PopularSupplierCard({
  user_id,
  icon,
  title,
  name,
  slug,
  type,
}: PopularSupplierCardProps) {
  const router = useRouter();
  const { currentLocale } = useSettingsContext();
  const handleData = (slug: string) => {
    if (type === "Supplier") {
      router.push(`/business-network/supplier-profile/${slug}`);
    } else {
      router.push(`/business-network/buyer-profile/${slug}`);
    }
  };
  const networkTabText = useTranslations("networkTabText")
  return (
    <div className="my-2 px-2">
      <div className={`bg-white rounded-4 overflow-hidden p-3 popular-supplier-card ${currentLocale === 'en' ? 'text-start' : 'text-end'}`}>
        <img
          loading="lazy"
          className={`rounded-circle my-2 d-block ${currentLocale === 'en' ? 'me-auto':'ms-auto'}`}
          alt="image"
          src={
            icon
              ? AMAZONE_MEDIA_URL + "User/" + user_id + "/BusinessLogo/" + icon
              : default_supplier_logo
          }
          width={50}
          height={50}
        />
        <h5 className="main-heading main-heading-tablet text-black fs-6 fw-normal my-3 popular-supplier">
          {title}
        </h5>
        {/* <h5
          className="main-heading main-heading-tablet text-black fs-7 fw-light"
          style={{ minHeight: "43px" }}
        >
     
        </h5> */}
        <div className={`d-flex align-items-center gap-2  ${currentLocale === 'en' ? '':'flex-row-reverse'}`}>
             <span className="bg-white rounded-circle border border-dark border-1 text-black f-7 fw-bold p-1">
            SH
          </span>{" "}
    <span className="text-12">      {name}</span>
          </div>
        <div className={`d-flex justify-content-between mt-4 ${currentLocale === 'en' ? '':'flex-row-reverse'}`}>
          <Button
            variant="dark"
            className="text-white rounded-5 fs-7"
            size="sm"
            onClick={() => handleData(slug || "")}
          >
            {networkTabText("invite")}
          </Button>
          <Button
            variant="light"
            className="text-black rounded-5 fs-7"
            size="sm"
            onClick={() => handleData(slug || "")}
          >
            {networkTabText("chat")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default PopularSupplierCard;
