import {
  AccountSelectionData,
  BenefitsCardProps,
  BlogCardData,
  BlogEprocureCardData,
  BlogsNavData,
  ExpertCardProps,
  ExportDashboardCardProps,
  FactsNumbersCardItem,
  ImageItem,
  InfoCardProps,
  MarketplaceImageSectionDataProps,
  NetworkBannerData,
  NetworkCardData,
  OfferCardProps,
  PlanData,
  PopularSearchesData,
  PopularSupplierCardProps,
  ProcurementCardData,
  SearchApiProps,
  SearchCardData,
  SearchTypesData,
  bussinessTypeCardData,
  pulicRfpData,
} from "../types";

export const BRANDS_NAME = [
  "facebook",
  "google",
  "heroku",
  "microsoft",
  "netflix",
  "slack",
  "tripadvisor",
];

export const brands = BRANDS_NAME.map((brand, index) => ({
  id: `e99f09a7-dd88-49d5-b1c8-1daf80c2d7b${index + 1}`,
  name: brand,
  image: `../assets/logo/${brand}.svg`,
}));

export const PlanDataDummy: Array<PlanData> = [
  {
    heading: "Starter",
    subHeading: "Free forever",
    content: "Hit the ground running with e-Procure forever",
    validText: "",
    buttonText: "Free Trial",
    buttonLink: "#",
    buttonVariant: "primary",
    image: "/assets/img/starterIcon.png",
  },
  {
    heading: "Professional",
    subHeading: "80 SAR",
    content: "Per Month /4 RFX transactions.",
    validText: "Valid upto 3 months",
    buttonText: "Subscribe Now",
    buttonLink: "#",
    buttonVariant: "outline-info",
    image: "/assets/img/professionalIcon.png",
  },
  {
    heading: "B2B Marketplace",
    subHeading: "800 SAR",
    content: "Unlimited transactions",
    validText: "For the entire year.",
    buttonText: "Get Advanced Plan",
    buttonLink: "#",
    buttonVariant: "outline-secondary",
    image: "/assets/img/advancedIcon.png",
    tag: "Value for money",
  },
];

export const MarketplaceImageSectionData: Array<MarketplaceImageSectionDataProps> =
  [
    {
      id: 1,
      background: "contact-mask-card-1",
      heading: "Selling as a business?",
      image: "/assets/img/marketplace1.png",
    },
    {
      id: 2,
      background: "contact-mask-card-2",
      heading: "Selling as a trader?",
      image: "/assets/img/marketplace2.png",
    },
  ];
export const networkBannerSectionData: Array<MarketplaceImageSectionDataProps> =
  [
    {
      id: 1,
      background: "network-banner-1",
      heading: "Selling as a business?",
    },
    {
      id: 2,
      background: "network-banner-2",
      heading: "Selling as a trader?",
    },
  ];

export const blogDataDummy: Array<BlogCardData> = [
  {
    id: "1",
    heading:
      "Improve Procurement Department Results through Process-Oriented Design",
    content:
      "This is an Article Review of Stains on the Bright Side of Process-Oriented Organizational Designs.",
    image: "/assets/blog/1.jpg",
    slug: "test-blog",
  },
  {
    id: "2",
    heading: "How Procurement and Finance Can Collaborate",
    content:
      "This is an Article Review of Stains on the Bright Side of Process-Oriented Organizational Designs.",
    image: "/assets/blog/3.jpg",
    slug: "test-blog",
  },
  {
    id: "3",
    heading: "How Businesses can thrive during & post Covid-19 Pandemic?",
    content:
      "This is an Article Review of Stains on the Bright Side of Process-Oriented Organizational Designs.",
    image: "/assets/blog/4.jpg",
    slug: "test-blog",
  },
];

export const infoCardDataDummy: Array<InfoCardProps> = [
  {
    heading: "Mission",
    content:
      "Empowering B2B buyers and suppliers to manage, streamline and automate, B2B paymnets, procurement and B2B e-commerce operations through a secure blockchain network from anywhere and anytime💡☁️ 🚀",
    image: "/assets/img/mission.png",
  },
  {
    heading: "Values",
    content:
      "We believe that quality, integrity and flexibility is an essential basis for the progress and success of any business. Our main value is to provide and excellent and credible solution to our partners 😄🤝👍",
    image: "/assets/img/values.png",
  },
  {
    heading: "Vision",
    content:
      "To be a pioneer in making, Procurement, B2B e-commerce, B2B Payments and Enterprise Solutions simpler, accessible, manageable and more affordable to all companies at any size around the world 🌎🌍🌏",
    image: "/assets/img/vision.png",
  },
];

export const factsNumbersCardPropsItems: Array<FactsNumbersCardItem> = [
  {
    title: "8000+",
    content: "Qualified",
    tag: "Buyers",
    src: "/assets/img/qualified.png",
    bgColor: "#F2F9FF",
  },
  {
    title: "10000+",
    content: "Merchant /",
    tag: "Suppliers",
    src: "/assets/img/merchant.png",
    bgColor: "#FAF1FE",
  },
  {
    title: "17+ yrs",
    content: "Since 2006",
    bgColor: "#F7FDEA",
  },
  {
    title: "10000+",
    content: "Since 2006",
    bgColor: "#F2F2F2",
  },
  {
    title: "$23.54",
    content: "Revenue",
    bgColor: "#F7FDEA",
  },
];

export const sliderImages: Array<ImageItem> = [
  { src: "/assets/slider/slide-1.png" },
  { src: "/assets/slider/slide-2.png" },
  { src: "/assets/slider/slide-3.png" },
  { src: "/assets/slider/slide-4.png" },
  { src: "/assets/slider/slide-5.png" },
];

export const offerCardPropsItems: Array<OfferCardProps> = [
  {
    title: "Marketplace",
    content:
      "Quickly find all your company needs to procure products & services in one place, with an easy and fast business shopping experience",
    src: "/assets/offers/marketplace.png",
    bgColor: "#E7FCEF",
  },
  {
    title: "Strategic Sourcing",
    content:
      "Cut your expenses effectively and get all that you need with an easiest & fastest cloud procurement experience, without paper work & without third-party authentication",
    src: "/assets/offers/strategicSourcing.png",
    bgColor: "#EDF5FF",
  },
  {
    title: "e-Auction",
    content:
      "Platforms that specialize in hosting live e-auctions to get company needs of procurement fulfilled with the highest quality, from the best suppliers, and at the lowest possible cost.",
    src: "/assets/offers/eAuction.png",
    bgColor: "#FCF7EA",
  },
  {
    title: "Supplier Management",
    content:
      "A complete service to easily manage, work & invite any supplier from anywhere and anytime",
    src: "/assets/offers/supplierManagement.png",
    bgColor: "#F6F4FC",
  },
  {
    title: "PO Management",
    content:
      "Modern cloud procurement experience to eliminate paperwork, and shorten procurement cycles",
    src: "/assets/offers/pOManagement.png",
    bgColor: "#FAF1FE",
  },
  {
    title: "Expert Hiring",
    content:
      "A global network of expert freelancers in various business sectors with an extensive range of service on flexible pricing, and at any time",
    src: "/assets/offers/expertHiring.png",
    bgColor: "#F2F9FF",
  },
  {
    title: "Smart Contracts",
    content:
      "The smart contract solution for transparent real time tracking and completely secure business experience",
    src: "/assets/offers/smartContracts.png",
    bgColor: "#F4F6FC",
  },
  {
    title: "e-Invoice Management",
    content:
      "An automated feature for your invoice management to save your time which brings ease to your accounting department",
    src: "/assets/offers/eAueInvoiceManagementction.png",
    bgColor: "#F7FDEA",
  },
  {
    title: "Spend Analytics",
    content:
      "A powerful spend analytics tool to manage your procurement and analyze your spending with ease",
    src: "/assets/offers/spendAnalytics.png",
    bgColor: "#FFF2F2",
  },
];

export const bussinessTypeCardDummy: Array<bussinessTypeCardData> = [
  {
    image: "/assets/contact/Image160.png",
    heading: "Sales",
    content: "Find out how we can help your business",
    subcontent: "CHAT WITH SALES",
  },
  {
    image: "/assets/contact/Image162.png",
    heading: "Partners",
    content:
      "We love partnering with other businesses. Let us know what you’re thinking.",
    subcontent: "CONTACT US",
  },
  {
    image: "/assets/contact/Image163.png",
    heading: "Support",
    content: "Have a support question? We’ve got answers.",
    subcontent: "CONTACT SUPPORT",
  },
  {
    image: "/assets/contact/Image161.png",
    heading: "Others",
    content: "Any media questions or ideas you have, we’re happy to connect.",
    subcontent: "CONNECT HERE",
  },
];

export const blogEprocureData: Array<BlogEprocureCardData> = [
  {
    heading:
      "Improve Procurement Department Results through Process-Oriented Design",
    content:
      "This is an Article Review of Stains on the Bright Side of Process-Oriented Organizational Designs.",
    image: "/assets/blog/3.jpg",
    slug: "/blog-details",
  },
  {
    heading: "How Procurement and Finance Can Collaborate",
    content:
      "This is an Article Review of Stains on the Bright Side of Process-Oriented Organizational Designs.",
    image: "/assets/blog/4.jpg",
    slug: "/blog-details",
  },
  {
    heading: "How Businesses can thrive during & post Covid-19 Pandemic?",
    content:
      "With Covid-19 pandemic and its economic effects arising around the world, learn How companies and institutions globally can thrive and survive the implications, economic challenges and supply chain fluctuations.",
    image: "/assets/blog/1.jpg",
    slug: "/blog-details",
  },
  {
    heading:
      "Improve Procurement Department Results through Process-Oriented Design",
    content:
      "This is an Article Review of Stains on the Bright Side of Process-Oriented Organizational Designs.",
    image: "/assets/blog/3.jpg",
    slug: "/blog-details",
  },
  {
    heading: "How Procurement and Finance Can Collaborate",
    content:
      "This is an Article Review of Stains on the Bright Side of Process-Oriented Organizational Designs.",
    image: "/assets/blog/4.jpg",
    slug: "/blog-details",
  },
  {
    heading: "How Businesses can thrive during & post Covid-19 Pandemic?",
    content:
      "With Covid-19 pandemic and its economic effects arising around the world, learn How companies and institutions globally can thrive and survive the implications, economic challenges and supply chain fluctuations.",
    image: "/assets/blog/1.jpg",
    slug: "/blog-details",
  },
  {
    heading:
      "Improve Procurement Department Results through Process-Oriented Design",
    content:
      "This is an Article Review of Stains on the Bright Side of Process-Oriented Organizational Designs.",
    image: "/assets/blog/3.jpg",
    slug: "/blog-details",
  },
  {
    heading: "How Procurement and Finance Can Collaborate",
    content:
      "This is an Article Review of Stains on the Bright Side of Process-Oriented Organizational Designs.",
    image: "/assets/blog/4.jpg",
    slug: "/blog-details",
  },
  {
    heading: "How Businesses can thrive during & post Covid-19 Pandemic?",
    content:
      "With Covid-19 pandemic and its economic effects arising around the world, learn How companies and institutions globally can thrive and survive the implications, economic challenges and supply chain fluctuations.",
    image: "/assets/blog/1.jpg",
    slug: "/blog-details",
  },
];

export const procurementData: Array<ProcurementCardData> = [
  {
    heading: "Utility Company",
    percentage: "80%",
    content: "Inbound Interactions Automated",
  },
  {
    heading: "Efficiency Amplified",
    percentage: "90%",
    content: "Automation Rate",
  },
  {
    heading: "Continental Coverage",
    percentage: "14+",
    content: "Our Global Presence & Commitment",
  },
  {
    heading: "Multilingual Mastery",
    percentage: "30+",
    content: "Fostering Inclusivity & Understanding",
  },
  {
    heading: "Strategic Collaborations",
    percentage: "80+",
    content: "International Partners",
  },
];

export const benefitCardPropsItems: Array<BenefitsCardProps> = [
  {
    title: "Increased Efficiency",
    content:
      "e-Procurement and B2B online trading streamline procurement processes, reducing manual effort and paperwork, leading to increased efficiency",
  },
  {
    title: "Cost Savings",
    content:
      "Automation and improved process efficiency result in cost savings by reducing administrative expenses, negotiation costs, and inventory holding costs.",
  },
  {
    title: "Enhanced Supplier Management",
    content:
      "e-Procurement platforms enable better supplier management, including supplier selection, performance evaluation, and collaboration.",
  },
  {
    title: "Expanded Market Reach",
    content:
      "B2B online trading allows businesses to reach a wider audience and explore new markets globally, leading to increased sales opportunities.",
  },
  {
    title: "Improved Transparency",
    content:
      "Online platforms provide visibility into pricing, product specifications, and transaction history, enhancing transparency in B2B transactions.",
  },
];

export const networkCardDummy: Array<NetworkCardData> = [
  {
    image: "/assets/network-tabs/network-1.png",
  },
  {
    image: "/assets/network-tabs/network-2.png",
  },
  {
    image: "/assets/network-tabs/network-3.png",
  },
  {
    image: "/assets/network-tabs/network-4.png",
  },
];

export const ExpertCardData: Array<ExpertCardProps> = [
  {
    image: "/assets/profilePictures/1.jpg",
    firstName: "Kaushal",
    lastName: "Rajpurohit",
  },
  {
    image: "/assets/profilePictures/2.jpg",
    firstName: "Nazreen",
    lastName: "Shaikh",
  },
  {
    image: "/assets/profilePictures/3.jpg",
    firstName: "Nandeep",
    lastName: "Barochiya",
  },
  {
    image: "/assets/profilePictures/4.jpg",
    firstName: "Varun",
    lastName: "Prajapati",
  },
  {
    image: "/assets/profilePictures/5.jpg",
    firstName: "Shehnaaz",
    lastName: " Azzra abdul",
  },
];

export const categoryItems = [
  "Mineral and Textile and Inedible Plant and Animal Materials",
  "Live Plant and Animal Material and Accessories and Supplies",
  "Resin and Rosin and Rubber and Foam and Film and Elastomeric Materials",
  "Tools and General Machinery",
  "Tools and General Machinery",
];

export const publicRFPDummy: Array<pulicRfpData> = [
  {
    image: "/assets/blog/1.jpg",
    heading: "Ingenico Payment Terminal iCT220 Technical Sheet",
    content: "Share a proposal",
  },
  {
    image: "/assets/blog/3.jpg",
    heading: "Pre-Engineered Buildings Supply & Install",
    content: "Share a proposal",
  },
  {
    image: "/assets/blog/1.jpg",
    heading: "Ingenico Payment Terminal iCT220 Technical Sheet",
    content: "Share a proposal",
  },
  {
    image: "/assets/blog/3.jpg",
    heading: "Pre-Engineered Buildings Supply & Install",
    content: "Share a proposal",
  },
];

export const productImgDummy: Array<any> = [
  {
    image: "/assets/blog/1.jpg",
  },
  {
    image: "/assets/blog/3.jpg",
  },
  {
    image: "/assets/blog/4.jpg",
  },
  {
    image: "/assets/blog/1.jpg",
  },
  {
    image: "/assets/blog/3.jpg",
  },
  {
    image: "/assets/blog/1.jpg",
  },
  {
    image: "/assets/blog/3.jpg",
  },
  {
    image: "/assets/blog/4.jpg",
  },
  {
    image: "/assets/blog/1.jpg",
  },
  {
    image: "/assets/blog/3.jpg",
  },
];

export const AccountSelectionDataDummy: Array<AccountSelectionData> = [
  {
    heading: "Buyer",
    content:
      "Get a workspace to automate, streamline and simplify your procurement operation",
    image: "/assets/blog/buyer.png",
  },
  {
    heading: "Supplier",
    content:
      "A supplier workspace to easily create a B2B commerce store within minutes",
    image: "/assets/blog/supplier.png",
  },
  {
    heading: "Expert",
    content:
      "For freelance experts who want to work with companies on projects and earn side income",
    image: "/assets/blog/expert.png",
  },
];

export const productListDummy: Array<any> = [
  {
    suspend: false,
    prepayment: false,
    type: "category",
    id: 645,
    is_suspend: false,
    title: "Addidas Shoe",
    inventory_type: "Service",
    is_featured: false,
    description: "Shoes",
    price: 150.0,
    media_path: "254_Image_1674488700742.jpg",
    media_title: "adidas._UL1500_.jpg",
    product_variation_id: 799,
    total_reviews: 0,
    is_price_visible: true,
    is_variation_available: false,
    created_date: "2023-01-23T15:45:01.442+0000",
    is_pre_payment: false,
    is_active: true,
    supplier_id: 987,
    variation_id: 799,
  },
  {
    suspend: false,
    prepayment: false,
    type: "category",
    id: 607,
    is_suspend: false,
    title: "Clothes",
    inventory_type: "Product",
    is_featured: false,
    description: "xaXAxax",
    price: 202.0,
    inventory_count: -130,
    media_path: "254_Image_1637830801350.png",
    media_title: "254_bl_1576764210353.png",
    total_reviews: 0,
    is_price_visible: true,
    is_variation_available: true,
    created_date: "2021-11-25T09:00:04.200+0000",
    is_pre_payment: false,
    is_active: true,
    supplier_id: 796,
  },
  {
    suspend: false,
    prepayment: false,
    type: "category",
    id: 31,
    is_suspend: false,
    title:
      "Double A A4 Copier Paper 80gsm-500Sht- Bulk Discount-30Ream-RM11.78/Ream",
    inventory_type: "Product",
    is_featured: false,
    description:
      "Double A A4 Copier Paper 80gsm-500Sheet\nSuitable For – High Speed Copiers, Inkjet Printers, Laser Printers, Plain Paper Faxes.",
    price: 300.0,
    inventory_count: 2342648,
    media_path: "254_Image_1581970887238.jpeg",
    media_title: "keke.jpeg",
    product_variation_id: 40,
    avg_rating: 4.0,
    total_reviews: 6,
    is_price_visible: true,
    is_variation_available: false,
    created_date: "2020-02-17T20:21:28.522+0000",
    is_pre_payment: false,
    is_active: true,
    supplier_id: 276,
    variation_id: 40,
  },
  {
    suspend: false,
    prepayment: false,
    type: "category",
    id: 51,
    is_suspend: false,
    title: "Cladding & Building Accessories Supply & Install ",
    inventory_type: "Product",
    is_featured: false,
    description:
      "Cladding:\n\nOur standard cladding for both single skin sheeting and sandwich panel made out of galvanized or zinc-aluminum color coated sheeting coils.\n\nApplications of our Cladding:\n- Roof Cladding\n- Wall Cladding\n- Liner Panel\n- Trim Details\n\n\nBuilding Accessories and Additions\nWe supply wide range of building accessories and additions. Below are some of our supplied items:\n\nBuilding Additions:\n1. Mezzanine Systems\n2. Crane Systems\n3. Canopies\n4. Fascia\n\nAccessories:\nDoors, Windows, Lovers, Gutters, Roof Curbs, Ridge Ventilator, Translucent Panel, etc.",
    inventory_count: 3214424525323,
    media_path: "254_Image_1593095181256.jpg",
    media_title: "port_4.jpg",
    product_variation_id: 91,
    total_reviews: 0,
    is_price_visible: false,
    is_variation_available: false,
    created_date: "2020-06-25T14:26:21.433+0000",
    is_pre_payment: false,
    is_active: true,
    supplier_id: 356,
    variation_id: 91,
  },
  {
    suspend: false,
    prepayment: false,
    type: "category",
    id: 83,
    is_suspend: false,
    title: "Comix Office-Desk Stationery 5 in 1 Kit",
    inventory_type: "Product",
    is_featured: false,
    description:
      "Comix Office-Desk Stationery 5 in 1 Kits: Pen-Holder, Sticky-Note Stand, Tape-Dispenser, Students' Scissors and Stapler for Home/School/Office use ED321",
    price: 120.0,
    inventory_count: 782,
    media_path: "254_Image_1611462999772.jpg",
    media_title: "51vrpdY6zYL._AC_SY400_.jpg",
    product_variation_id: 123,
    total_reviews: 0,
    is_price_visible: true,
    is_variation_available: false,
    created_date: "2021-01-19T09:53:22.345+0000",
    is_pre_payment: false,
    is_active: true,
    supplier_id: 276,
    variation_id: 123,
  },
  {
    suspend: false,
    prepayment: false,
    type: "category",
    id: 659,
    is_suspend: false,
    title: "Cap",
    inventory_type: "Product",
    is_featured: false,
    description: "Nike cap",
    price: 10.0,
    inventory_count: 15,
    media_path: "254_Image_1685611394377.png",
    media_title: "Screenshot 2023-05-02 at 18.54.58.png",
    product_variation_id: 813,
    total_reviews: 0,
    is_price_visible: true,
    is_variation_available: false,
    created_date: "2023-06-01T09:23:16.222+0000",
    is_pre_payment: false,
    is_active: true,
    supplier_id: 1025,
    variation_id: 813,
  },
];
export const mapStyles = [
  {
    featureType: "administrative",
    elementType: "all",
    stylers: [
      {
        saturation: "-100",
      },
    ],
  },
  {
    featureType: "administrative.province",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 65,
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: "50",
      },
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "all",
    stylers: [
      {
        saturation: "-100",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "all",
    stylers: [
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "all",
    stylers: [
      {
        lightness: "30",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "all",
    stylers: [
      {
        lightness: "40",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [
      {
        saturation: -100,
      },
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        hue: "#ffff00",
      },
      {
        lightness: -25,
      },
      {
        saturation: -97,
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels",
    stylers: [
      {
        lightness: -25,
      },
      {
        saturation: -100,
      },
    ],
  },
];
export const mapStylesContactDark = [
  {
    elementType: "geometry",
    stylers: [
      { color: "#242f3e" },  // Dark background for the map
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      { color: "#242f3e" },  // Text stroke matches the map background
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      { color: "#746855" },  // Slightly lighter text color for labels
    ],
  },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [
      { color: "#d59563" },  // Highlight administrative locality labels
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      { color: "#d59563" },  // Points of Interest (POI) labels in a lighter color
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      { color: "#263c3f" },  // Parks have a distinct dark green color
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      { color: "#6b9a76" },  // Labels for parks in a lighter green shade
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      { color: "#38414e" },  // Roads have a darker shade to stand out
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [
      { color: "#212a37" },  // Stroke on roads with a darker hue for clarity
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [
      { color: "#9ca5b3" },  // Lighter text for road labels
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      { color: "#746855" },  // Highways with a distinct brownish tone
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      { color: "#1f2835" },  // Darker stroke for highway roads
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      { color: "#f3d19c" },  // Lighter text for highway labels
    ],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [
      { color: "#2f3948" },  // Darker transit routes
    ],
  },
  {
    featureType: "transit.station",
    elementType: "labels.text.fill",
    stylers: [
      { color: "#d59563" },  // Transit station labels with a lighter shade
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      { color: "#17263c" },  // Darker shade for water bodies
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      { color: "#515c6d" },  // Light grey for water labels
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.stroke",
    stylers: [
      { color: "#17263c" },  // Darker stroke around water label text
    ],
  },
];


export const mycart = [
  {
    created_date: "2021-01-19T09:53:22.345+0000",
    description:
      "Comix Office-Desk Stationery 5 in 1 Kits: Pen-Holder, Sticky-Note Stand, Tape-Dispenser, Students' Scissors and Stapler for Home/School/Office use ED321",
    id: 83,
    inventory_count: 782,
    inventory_type: "Product",
    is_active: true,
    is_featured: false,
    is_pre_payment: false,
    is_price_visible: true,
    is_suspend: false,
    is_variation_available: false,
    media_path: "254_Image_1611462999772.jpg",
    media_title: "51vrpdY6zYL._AC_SY400_.jpg",
    prepayment: false,
    price: 120,
    product_variation_id: 123,
    supplier_id: 276,
    suspend: false,
    title: "Comix Office-Desk Stationery 5 in 1 Kit",
    total_reviews: 0,
    type: "category",
    variation_id: 123,
  },
  {
    created_date: "2023-06-01T09:23:16.222+0000",
    description: "Nike cap",
    id: 659,
    inventory_count: 15,
    inventory_type: "Product",
    is_active: true,
    is_featured: false,
    is_pre_payment: false,
    is_price_visible: true,
    is_suspend: false,
    is_variation_available: false,
    media_path: "254_Image_1685611394377.png",
    media_title: "Screenshot 2023-05-02 at 18.54.58.png",
    prepayment: false,
    price: 10,
    product_variation_id: 813,
    supplier_id: 1025,
    suspend: false,
    title: "Cap",
    total_reviews: 0,
    type: "category",
    variation_id: 813,
  },
];

export const topBrandsDummy: Array<NetworkCardData> = [
  {
    image: "/assets/brand/polycab.jpg",
  },
  {
    image: "/assets/brand/pla1.png",
  },
  {
    image: "/assets/brand/siemens.png",
  },
  {
    image: "/assets/brand/unbrako.png",
  },
  {
    image: "/assets/brand/bonfiglioli1.jpg",
  },
  {
    image: "/assets/brand/bonfiglioli1.jpg",
  },
];

export const NetworkBannerDummy: Array<NetworkBannerData> = [
  {
    id: 1,
    heading: "Banners by Suppliers who has paid for the space",
    content: "Revenue generating opportunity",
  },
  {
    id: 2,
    heading: "Banners by Suppliers who has paid for the space",
    content: "Revenue generating opportunity",
  },
  {
    id: 3,
    heading: "Banners by Suppliers who has paid for the space",
    content: "Revenue generating opportunity",
  },
  {
    id: 4,
    heading: "Banners by Suppliers who has paid for the space",
    content: "Revenue generating opportunity",
  },
];

export const BlogsNavDummy: Array<BlogsNavData> = [
  {
    title: "News",
  },
  {
    title: "Blockchain",
  },
  {
    title: "Procurement",
  },
  {
    title: "Remote Work",
  },
  {
    title: "E-Procurement",
  },
  {
    title: "B2B",
  },
  {
    title: "Sales",
  },
  {
    title: "Insights",
  },
  {
    title: "Suppliers",
  },
  {
    title: "Logistics",
  },
  {
    title: "Solution",
  },
];

export const NetworkTabsDummy: Array<BlogsNavData> = [
  {
    title: "Agricultural Industry",
  },
  {
    title: "Electrical wires & Equipments",
  },
  {
    title: "Cleaning Services & Agents",
  },
  {
    title: "Home Decore",
  },
  {
    title: "Office Stationaries & Equipments",
  },
  {
    title: "Cleaning Services & Agents",
  },
  {
    title: "Automotive parts and equipments",
  },
  {
    title: "AC repair services",
  },
];

export const ExpertTabsDummy: Array<BlogsNavData> = [
  {
    title: "PAYMENT",
  },
  {
    title: "SERVICES",
  },
  {
    title: "LEGAL",
  },
  {
    title: "REGISTRATION",
  },
  {
    title: "BUYER",
  },
  {
    title: "BILLING",
  },
  {
    title: "SUPPLIER",
  },
];

export const ExpertListDummy: Array<BlogsNavData> = [
  {
    title: "Is the smart contract legally binding?",
  },
  {
    title: "What are the milestone payments?",
  },
  {
    title: "Can an expert check the payment history and save the invoices?",
  },
  {
    title:
      "What are the measures taken by e-Procure to protect my business info?",
  },
  {
    title: "Is the smart contract legally binding?",
  },
  {
    title: "What are the milestone payments?",
  },
  {
    title: "Can an expert check the payment history and save the invoices?",
  },
  {
    title:
      "What are the measures taken by e-Procure to protect my business info?",
  },
];

export const SearchCardDummy: Array<SearchCardData> = [
  {
    image: "/assets/search/1.webp",
    heading: "Products",
    content: "Over 100K",
  },
  {
    image: "/assets/search/4.webp",
    heading: "Services",
    content: "More than 5K",
  },
  {
    image: "/assets/search/3.webp",
    heading: "Suppliers",
    content: "Get the best",
  },
  {
    image: "/assets/search/4.webp",
    heading: "Buyers",
    content: "Venture up soon",
  },
  {
    image: "/assets/search/1.webp",
    heading: "Experts",
    content: "Get started",
  },
];

export const PopularSearchesDummy: Array<PopularSearchesData> = [
  {
    title: "Plumbing Services",
  },
  {
    title: "Office Furniture",
  },
  {
    title: "IT technician",
  },
  {
    title: "House keeping services",
  },
  {
    title: "Hardware equipments",
  },
  {
    title: "Open a market place",
  },
];

export const SearchTypesDummy: Array<SearchTypesData> = [
  {
    image: "/assets/blog/1.jpg",
  },
  {
    image: "/assets/blog/3.jpg",
  },
  {
    image: "/assets/blog/4.jpg",
  },
  {
    image: "/assets/blog/1.jpg",
  },
];

export const searchApiDummy: SearchApiProps = {
  buyer: [
    {
      avg_rating: 0,
      business_logo_path: "254_bl_1568815168721.png",
      business_name: "HG Business One",
      id: 34,
      is_active: true,
      total_reviews: 0,
      user_type_id: 2,
    },
    {
      avg_rating: 0,
      business_logo_path: "254_bl_1588047583264.jpg",
      business_name: "Buyer one",
      id: 242,
      is_active: true,
      total_reviews: 0,
      user_type_id: 2,
    },
    {
      avg_rating: 0,
      business_logo_path: "254_bl_1571312448487.png",
      business_name: "HG Business Hub One",
      id: 99,
      is_active: true,
      total_reviews: 0,
      user_type_id: 2,
    },
    {
      avg_rating: 0,
      business_name: "IPhone business",
      id: 143,
      is_active: true,
      total_reviews: 0,
      user_type_id: 2,
    },
  ],
  buyer_count: 4,
  expert: [
    {
      avg_rating: 0,
      id: 211,
      is_active: true,
      name: "Expert One",
      total_reviews: 0,
      user_type_id: 4,
    },
    {
      avg_rating: 0,
      id: 195,
      is_active: true,
      name: "hitesh gamione",
      total_reviews: 0,
      user_type_id: 4,
    },
    {
      avg_rating: 0,
      id: 743,
      is_active: true,
      name: "someone testtt",
      profile_picture_path: "254_p_1627041115438.png",
      total_reviews: 0,
      user_type_id: 4,
    },
    {
      avg_rating: 0,
      id: 749,
      is_active: true,
      name: "someone testtt",
      profile_picture_path: "254_p_1629288480563.png",
      total_reviews: 0,
      user_type_id: 4,
    },
  ],
  expert_count: 4,
  product: [
    {
      avg_rating: 0,
      id: 3,
      is_active: true,
      media_path: "254_Image_1568802343032.jpeg",
      parent_id: 30,
      seller_id: 30,
      title: "Iphone repair ",
      total_reviews: 0,
    },
    {
      avg_rating: 0,
      id: 21,
      is_active: true,
      media_path: "254_Image_1574064708830.jpeg",
      parent_id: 57,
      seller_id: 57,
      title: "iPhone 11",
      total_reviews: 0,
    },
    {
      avg_rating: 0,
      id: 22,
      is_active: true,
      media_path: "254_Image_1574064850482.jpeg",
      parent_id: 57,
      seller_id: 57,
      title: "iPhone Support",
      total_reviews: 0,
    },
    {
      avg_rating: 0,
      id: 35,
      is_active: true,
      media_path: "254_Image_1582805352211.jpg",
      parent_id: 244,
      seller_id: 244,
      title: "Headphones",
      total_reviews: 0,
    },
  ],
  product_count: 4,
  supplier: [
    {
      avg_rating: 0,
      business_name: "Business one",
      id: 244,
      is_active: true,
      total_reviews: 0,
      user_type_id: 1,
    },
    {
      avg_rating: 0,
      business_name: "Buyer one",
      id: 248,
      is_active: true,
      total_reviews: 0,
      user_type_id: 1,
    },
    {
      avg_rating: 0,
      business_name: "Buyer one",
      id: 249,
      is_active: true,
      total_reviews: 0,
      user_type_id: 1,
    },
    {
      avg_rating: 0,
      business_name: "Test iphone supplier",
      id: 235,
      is_active: true,
      total_reviews: 0,
      user_type_id: 1,
    },
  ],
  supplier_count: 4,
};

export const expertDadhboardCards: Array<ExportDashboardCardProps> = [
  {
    id: 1,
    logo: "/assets/expert-dashboard/1.png",
    heading: "Constant Stream of Projects",
    content:
      "If you are looking for a platform that will allow you to work on unique projects anywhere and anytime from the best companies from around the world, at competitive rates, then the experts platform is your ideal solution.",
    subContent: "OPEN PROJECTS",
  },
  {
    id: 2,
    logo: "/assets/expert-dashboard/2.png",
    heading: "Be a part of the blockchain network",
    content:
      "If you are looking for a platform that will allow you to work on unique projects anywhere and anytime from the best companies from around the world, at competitive rates, then the experts platform is your ideal solution.",
    subContent: "OPEN PROJECTS",
  },
  {
    id: 3,
    logo: "/assets/expert-dashboard/3.png",
    heading: "Get your payments on time",
    content:
      "If you are looking for a platform that will allow you to work on unique projects anywhere and anytime from the best companies from around the world, at competitive rates, then the experts platform is your ideal solution.",
    subContent: "OPEN PROJECTS",
  },
  {
    id: 4,
    logo: "/assets/expert-dashboard/4.png",
    heading: "Be a part of a new aspiring era",
    content:
      "If you are looking for a platform that will allow you to work on unique projects anywhere and anytime from the best companies from around the world, at competitive rates, then the experts platform is your ideal solution.",
    subContent: "OPEN PROJECTS",
  },
];

export const workspaceData: Array<any> = [
  {
    id: 1,
    business_logo: "/assets/img/qualified.png",
    business_name: "Designing Neosoft",
    email: "kinjal.0306@gmail.com",
    type: "BUYER",
  },
  {
    id: 2,
    business_logo: "/assets/img/qualified.png",
    business_name: "Designing Neosoft",
    email: "kinjal.0306@gmail.com",
    type: "SUPPLIER",
  },
  {
    id: 3,
    business_logo: "/assets/img/qualified.png",
    business_name: "Designing Neosoft",
    email: "kinjal.0306@gmail.com",
    type: "EXPERT",
  },
  {
    id: 4,
    business_logo: "/assets/img/qualified.png",
    business_name: "Designing Neosoft",
    email: "kinjal.0306@gmail.com",
    type: "BUYER",
  },
];
export const productColor: Array<any> = [
  "Orange",
  "Black",
  "Purple",
  "Brown",
  "Pink",
  "Blue",
  "Red",
];
