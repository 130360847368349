"use client";
import { useSettingsContext } from "@/context";
import { useRouter } from "@/navigation";
import { BlogCardProps } from "@/utils/types";
import { FaArrowRight } from "react-icons/fa";

function BlogMobileCard({ heading, content, image, slug, categoryTextName,author, }: BlogCardProps) {
  const router = useRouter();
  const slugRoutes = categoryTextName==="all" ? "blog" : categoryTextName==="News" ? "news" : categoryTextName==="Education" ? "education" : categoryTextName==="Solution" ? "solution" : categoryTextName==="Service" ? "service" : categoryTextName==="Case Studies" ? "case-studies" : "blog" ; 
  const dateFun = (createdAt: any) => {
    const date = new Date(createdAt);
    const formattedDate = date.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });
    return formattedDate;
  };
  const { themeDirection } =
  useSettingsContext();
  return (
    <div className="col-12 mt-3 d-flex justify-content-center">
      <div
        onClick={() => router.replace(`/blogs/${slugRoutes}/${slug}`)}
        className="row cursor-pointer overflow-hidden mobile-blog-card m-1"
      >
        <div className="col-4 mobile-card p-0">
          {image ? (
            <img
              loading="lazy"
              alt={categoryTextName}
              src={image}
              width="100%"
              height="100%"
              className="w-100 h-100"
              style={{objectFit:"cover"}}
            />
          ) : (
            <img
              loading="lazy"
              alt="placeholder-image"
              src="./assets/img/elementor-placeholder-image.webp"
              width="100%"
              height="100%"
              className="w-100 h-100"
              style={{objectFit:"cover"}}
            />
          )}
        </div>

        <div className="col-8 px-2">
        <div className=" mt-2 text-start">
        <span className={`blog-pill ${themeDirection==="rtl" && "pt-1"}`}>{categoryTextName}</span>
        </div>
          <h4
            className="main-heading text-black my-2  text-start text-16 fw-normal"
            style={{ minHeight: "35px" }}
          >
            {heading.length > 65 ? heading.substring(0, 65) + "..." : heading}

          </h4>
          <p className="fw-light m-0  pb-2 text-start" style={{ minHeight: "35px" }}>
            {content.length > 70 ? content.substring(0, 70) + "..." : content}
          </p>

          <div className=" d-flex align-items-center mb-2 gap-1">
          <img
          className="rounded-circle border"
          alt="author"
          src={author?.profileImg}
          width={25}
          height={25}
          loading="lazy"
        />
      <p className="text-14 fw-bold m-0 text-truncate text-start">
        {author?.firstName + " " + author.lastName} <br />
        <span className="text-14 fw-light">{(dateFun(author?.createdAt))}</span>
      </p>
        </div>
        </div>
      </div>
    </div>
  );
}

export default BlogMobileCard;
