"use client";
import React, { useState } from "react";
import Select from "react-select";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage, FormikProps } from "formik";
import { Button, Col, Row } from "@/components/bootstrap";
import { CompanySuggestionData, CountryCodeState, FormValues } from "@/utils/types";
import { baseUrl, contactBaseUrl } from "@/utils/constant";
import { fetchApiData } from "@/utils/appService";
import { useTranslations } from "next-intl";
import { title } from "process";
import { Link, useRouter } from "@/navigation";
import { MdOutlineSupport } from "react-icons/md";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { TbNotebook } from "react-icons/tb";
import { PiHandshakeFill } from "react-icons/pi";
import { CustomMobileField } from "../common";
import ContactUsCustomMobileField from "../common/contact-us-mobile-field";
import { FaSpinner } from "react-icons/fa";

const categories = [
  {
    id: 1,
    title: "Support",
    img: <MdOutlineSupport /> // Replace with the actual image URL
  },
  {
    id: 2,
    title: "General Inquiry",
    img: <BsFillInfoCircleFill /> // Replace with the actual image URL
  },
  {
    id: 3,
    title: "Suggestions / Recommendation",
    img: <TbNotebook />
    // Replace with the actual image URL
  },
  {
    id: 4,
    title: "Partners Inquiry",
    img: <PiHandshakeFill />
    // Replace with the actual image URL
  }
];


function Contact() {
  const router = useRouter();
  const [isError, setIsError] = useState<string>("false");
  const [mobileNumberLength, setMobileNumberLength] = useState<number | number[]>(9);
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [countryCode, setCountryCode] = useState<CountryCodeState>({
    country_id: "",
    dial_code: "",
  });
  const [companySuggestions, setCompanySuggestions] = useState<
    CompanySuggestionData[]
  >([]);
  let minLength, maxLength;
  if (Array.isArray(mobileNumberLength)) {
    minLength = Math.min(...mobileNumberLength);
    maxLength = Math.max(...mobileNumberLength);
  } else {
    minLength = maxLength = mobileNumberLength;
  }
  
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    company: Yup.string().required("Company name is required"),
    phone: Yup.string()
    .typeError("Mobile number must be a number")
    .required("This mobile no field is required.")
    .matches(/^\d+$/, "Phone number is not valid")
    .min(minLength, `Mobile number must be at least ${minLength} digits`)
    .max(maxLength, `Mobile number must not exceed ${maxLength} digits`),
    reason: Yup.string().required("Subject is required"),
    message: Yup.string().required("Message is required"),
  });
  const loadCompanySuggestions = async (newValue: string) => {
    try {
      const apiUrl = `${contactBaseUrl}/companies/suggest?query=${newValue}`;
  
      const response: CompanySuggestionData[] = await fetchApiData<
        CompanySuggestionData[]
      >(apiUrl);
  
      const isMatchFound = response.some(
        (company) =>
          company.name.toLowerCase() === newValue.toLowerCase() ||
          company.domain.toLowerCase() === newValue.toLowerCase()
      );
  
      if (!isMatchFound) {
        const newCompany = {
          name: newValue,
          domain: newValue,
          logo: "", 
        };
        response.push(newCompany);
      }
  
      setCompanySuggestions(response);
    } catch (error) {
      console.error("Error fetching company suggestions:", error);
    }
  };
  

  const CustomOption = ({ innerRef, innerProps, data }: any) => (
    <div
      ref={innerRef}
      {...innerProps}
      className="custom-option cursor-pointer"
    >
      {data.logo!=="" && <img src={data.logo} alt={data.label} className="company-logo my-2" />}
      {data.label}
    </div>
  );
  const CustomReasonOption = ({ innerRef, innerProps, data }: any) => (
    <div
      ref={innerRef}
      {...innerProps}
      className="custom-option cursor-pointer"
    >
      <p className="company-logo my-2 text-primary">{data.logo}  </p>
      {data.label}
    </div>
  );

  const handleSubmit = async (values: any, actions: any) => {
    setIsLoading(true)
    setIsError("false");
    actions.setSubmitting(true);

    try {
      const apiUrl = `${baseUrl}/v2/user/contact-us`;
      const response: any = await fetchApiData(apiUrl, "post", values, true);

      if (response?.status === 200) {
    setIsLoading(false)
        setIsError("success");
        router.push("/contact-us/thank-you")
      } else {
    setIsLoading(false)
        setIsError("error");
      }
    } catch (error) {
      console.error("Error submiting contact us:", error);
    }
  };
  const t = useTranslations('Index');
  return (
    <div className="container-fluid shadow p-3 mb-5 bg-body my-5 rounded-5">
      <h3 className="text-center mt-4">{t("Have_us")}</h3>
      <Formik
        initialValues={{
          name: "",
          email: "",
          company: "",
          phone: "",
          reason: "",
          message: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validateOnMount={true}
      >
        {( formikProps : FormikProps<any>) => (
          <Form className="mt-3">
            <Row className="form-input">
              <label htmlFor="name" className="visually-hidden">
                Name:
              </label>
              <Col xs={12} sm={12} md={12} lg={6} className="p-2">
                <Field
                  type="text"
                  className="inputcss rounded-3 width-basic from-text"
                  id="name"
                  name="name"
                  placeholder="Name"
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="error-message"
                />
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} className="p-2">
                <label htmlFor="email" className="visually-hidden">
                  email:
                </label>
                <Field
                  type="email"
                  className="inputcss rounded-3 width-basic from-text"
                  id="email"
                  name="email"
                  placeholder="Work Email"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="error-message"
                />
              </Col>
            </Row>
            <Row className="form-input">
              <Col xs={12} sm={12} md={12} lg={6} className="p-2">
                <label htmlFor="company" className="visually-hidden">
                  Company Name:
                </label>
                <Select
                  aria-label="Select company name"
                  options={companySuggestions.map((company) => ({
                    value: company.name,
                    label: company.name,
                    logo: company.logo,
                  }))}
                  onInputChange={(newValue) => {
                    if (newValue) {
                      loadCompanySuggestions(newValue);
                    }
                  }}
                  onChange={(e) => {
                    formikProps.setFieldValue("company", e?.value || "");
                  }}
                  placeholder="Enter company name"
                  isClearable
                  name="company"
                  className="rounded-3 width-basic from-text"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      padding: "12px 17px",
                      border: "1px solid #BEBEBE",
                      opacity: "1",
                      width: "100%",
                      height: "65px",
                      alignItems: "left",
                      borderRadius: "8px",
                    }),
                  }}
                  components={{ Option: CustomOption }}
                />
                <ErrorMessage
                  name="company"
                  component="div"
                  className="error-message"
                />
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} className="p-2">
                <label htmlFor="phone" className="visually-hidden">
                  Phone Number:
                </label>
                    <div className="custom-form-width">
                        <ContactUsCustomMobileField
                          mobileNumber={formikProps.values.phone}
                          setCountryCode={setCountryCode}
                          handleMobileNumberChange={formikProps.handleChange(
                            "phone"
                          )}
                          setMobileNumberLength={setMobileNumberLength}
                        />
                      </div>
                      <ErrorMessage
                  name="phone"
                  component="div"
                  className="error-message"
                />
              </Col>
            </Row>
            <Row className="form-input">
              <Col xs={12} sm={12} md={12} lg={12} className="p-2">
                <label htmlFor="reason" className="visually-hidden">
                  Select Reason
                </label>
                <Select
                  aria-label="Select reason"
                  options={categories.map((reason) => ({
                    value: reason.title,
                    label: reason.title,
                    logo: reason.img,
                  }))}
                  onChange={(e) => {
                    formikProps.setFieldValue("reason", e?.value || "");
                  }}
                  placeholder="Enter reason"
                  isClearable
                  name="reason"
                  className="rounded-3 width-basic from-text"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      padding: "12px 17px",
                      border: "1px solid #BEBEBE",
                      opacity: "1",
                      width: "100%",
                      height: "65px",
                      alignItems: "left",
                      borderRadius: "8px",
                    }),
                  }}
                  components={{ Option: CustomReasonOption }}
                />
                <ErrorMessage
                  name="reason"
                  component="div"
                  className="error-message"
                />
              </Col>
            </Row>
            {/* <Row className="form-input">
              <label htmlFor="reason" className="visually-hidden">
                Subject:
              </label>
              <Col xs={12}>
                <Field
                  type="text"
                  className="inputcss rounded-3 width-full-length from-text"
                  id="reason"
                  name="reason"
                  placeholder="Subject for connecting"
                />
                <ErrorMessage
                  name="reason"
                  component="div"
                  className="error-message"
                />
              </Col>
            </Row> */}
            <Row className="form-input mt-md-2">
              <label htmlFor="message" className="visually-hidden">
                Message:
              </label>
              <Col xs={12}>
                <Field
                  as="textarea"
                  className="inputcss rounded-3 width-full from-text"
                  id="message"
                  name="message"
                  rows="6"
                  placeholder="Write what you would like to know about e-procedure"
                />
                <ErrorMessage
                  name="message"
                  component="div"
                  className="error-message"
                />
              </Col>
            </Row>
            <div className="text-center mt-4 mb-5">
            <button
            type="submit"
            className="btn text-white rounded-5 secondary_btn from-text my-2 get-started-btn"
            disabled={isLoading}
          >
            {isLoading ? <FaSpinner className="spinner-icon" /> : t("Submit_Query")}
          </button>

              {isError === "success" && (
                <div className="alert-success">Successfully Submitted</div>
              )}
              <div className="info-label">
                {t("By_our")}{" "}
                <Link target="_blank" className="text-black a" href="/privacy-policy">
                  {t("privacy_policy")}
                </Link>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Contact;
