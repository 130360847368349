"use client";
import { FC, useEffect, useState } from "react";
import { NetworkImgCard } from "../common";
import { NetworkCardData } from "@/utils/types";
import { Variants } from "framer-motion";
import Reveal from "../common/Reveal";
import Skeleton from "react-loading-skeleton";

const NetworkImgSection: FC<any> = ({ supplierData }) => {
  const data = supplierData.slice(11, 15);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (supplierData) {
      setLoading(false);
    }
  }, [supplierData]);
  return (
    <div>
        <div className="row my-sm-4">
          {!loading ? (data.map((item: NetworkCardData, index: number) => {
            const cardVariants: Variants = {
              hidden: {
                y: 100,
              },
              visible: {
                y: 0,
                transition: {
                  duration: 0.5,
                  delay: 0.2 * (index + 1),
                },
              },
            };
            return (
              <NetworkImgCard
                key={index}
                id={item.supplier_id}
                image={item?.business_logo}
                cardVariants={cardVariants}
                slug={item?.slug}
              />
            );
          })):
          (
            Array.from({ length: 4 }, (_, index) => (
              <div className="col-md-3 mt-3" key={index}>
                    <Skeleton borderRadius={15} height={220} />
              </div>
            ))
          )
          }
        </div>  
    </div>
  );
};

export default NetworkImgSection;
