"use client";
import { FC, Fragment, useEffect, useState } from "react";
import Slider from "react-slick";
import { CallToAction, PanCard } from "../common";
import {
  ContentSectionProps,
  bannerSectionProps,
  brandItemsProps,
  brandSectionProps,
  plansSectionItem,
  plansSectionProps,
} from "@/utils/types";
import Reveal from "../common/Reveal";
import Skeleton from "react-loading-skeleton";
import NextImage from "../common/next-image";

const settingsSlider = {
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  speed: 3000,
  autoplaySpeed: 3000,
  infinite: true,
  variableWidth: true,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 900,
      settings: { slidesToShow: 4 },
    },
    {
      breakpoint: 600,
      settings: { slidesToShow: 2 },
    },
  ],
};

type props = {
  contentSectionData?: ContentSectionProps;
  plansSectionData?: plansSectionProps;
  bannerData?: bannerSectionProps;
  brandData?: brandSectionProps;
};

const ContentSection: FC<props> = ({
  contentSectionData,
  plansSectionData,
  bannerData,
  brandData,
}) => {
  const bannerHeading = bannerData?.title ?? "";
  const bannerSubheading = bannerData?.content;
  const [loading, setLoading] = useState(true);
const [data, setData] = useState<any>({});
  useEffect(() => {
    if (brandData) {
      setLoading(false);
      setData(brandData);
    }
  }, [brandData]);
  return (
    <div className="home-widget container-fluid p-md-3 py-4 px-lg-5">
      <Reveal width={"100%"}>
        <>
          <div className="text-black text-center fs-3 my-5">
           <div
              dangerouslySetInnerHTML={{
                __html: contentSectionData?.title || "",
              }}
            /> 
            <div
              dangerouslySetInnerHTML={{
                __html: contentSectionData?.headline || "",
              }}
            />
          </div>

          <div
            className="w-75 m-auto rounded mb-5"
            style={{ backgroundColor: "white" }}
          >
            <Slider {...settingsSlider} className="p-4">
              { data?.items?.map(
                (brand: brandItemsProps, index: number) => (
                  <Fragment key={brand.id}>
                    <div
                      className="position-relative"
                      style={{ width: "140px", height: "32px" }}
                    >
                      {/* <NextImage
                        src={brand?.mediaUrl || ""}
                        alt="brand-img"
                        style={{
                          objectFit: "contain",
                        }}
                        loading="lazy"
                        width={140}
                        height={32}
                        className="brandCards"
                      /> */}
                                            <img
                        src={brand?.mediaUrl || ""}
                        alt="brand-img"
                        style={{
                          objectFit: "contain",
                        }}
                        loading="lazy"
                        width="140px"
                        height="32px"
                        className="brandCards"
                      />
                    </div>
                  </Fragment>
                )
              )
            }
            </Slider>
          </div>
        </>
      </Reveal>
      {bannerData && (
        <Reveal width={"100%"}>
          <div className="m-auto px-lg-5 py-lg-5 px-md-4 py-md-5 px-3 py-3 mt-3">
            <CallToAction
              heading={bannerHeading}
              subHeading={bannerSubheading}
              buttonText={bannerData?.cta?.label}
              buttonLink={bannerData?.cta?.redirectionUrl}
              bgImage="../assets/img/bg-blue.webp"
            />
          </div>
        </Reveal>
      )}
      {plansSectionData && plansSectionData?.items?.length && (
        <div className="my-5">
          <Reveal width={"100%"}>
            <h3
              className="text-black text-center fs-3 px-md-11 px-4  my-5 "
              style={{ margin: "auto" }}
            >
              {plansSectionData?.headline}
            </h3>
          </Reveal>
          <Reveal width={"100%"}>
            <div className="row p-4 px-md-4 px-lg-5 mb-2 mb-md-0 plan-card-container">
              {plansSectionData?.items.map(
                (item: plansSectionItem, index: number) => {
                  return <PanCard key={index} item={item} />;
                }
              )}
            </div>
          </Reveal>
        </div>
      )}
    </div>
  );
};

export default ContentSection;
