"use client";
import { FC, useEffect, useRef, useState } from "react";
import { useRouter } from "@/navigation";
import { Variants } from "framer-motion";
import { Button } from "@/components/bootstrap";
import { BlogCard } from "../common";
import BlogMobileSection from "./blog-mobile-section";
import { stripHtmlTags } from "@/utils/helpers";
import { useClientMediaQuery } from "@/hooks/use-client-media-query";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton from "react-loading-skeleton";
import { useTranslations } from "next-intl";
import { useSettingsContext } from "@/context";

const ITEMS_PER_PAGE = 3;

const BlogSection: FC<any> = ({ catId }) => {
  const isMobile = useClientMediaQuery("(max-width: 768px)");
  const router = useRouter();
  const workerRef = useRef<Worker>();
  const { currentLocale } = useSettingsContext()
  const activeLang = currentLocale
  const [blogs, setBlogs] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const blogText = useTranslations("Home")
  const t = useTranslations("Index")
  useEffect(() => {
    const handleScroll = () => {
      const triggerHeight = 2500;
      if (window.scrollY >= triggerHeight && !workerRef.current) {
        setLoading(true);
        workerRef.current = new Worker(
          new URL("../../utils/workers", import.meta.url)
        );

        let blogPayload: any = {
          projection: {
            populate: "deep",
            locale: activeLang,
          },
        };

        if (catId !== undefined) {
          blogPayload = {
            filter: {
              category: {
                id: {
                  operator: "eq",
                  value: catId,
                },
              },
            },
          };
        }

        workerRef.current?.postMessage(blogPayload);
        workerRef.current.onmessage = (event: MessageEvent<number>) => {
          if (Array.isArray(event.data)) {
            setBlogs(event.data);
            setLoading(false);
          }
        };
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [blogs]);
  return (
    <div className="blog-widget container-fluid px-md-3 px-lg-5 py-4 home-widget">
      <h3 className="text-black text-center fs-3 my-4">
        <span>{blogText("article")}</span>
      </h3>
      {isMobile ? (
        blogs && (
          <BlogMobileSection item={ITEMS_PER_PAGE} blogs={blogs && blogs} />
        )
      ) : (
        <div className="row px-md-3 px-lg-5 w-100 justify-content-sm-center">
          {loading &&
            Array.from({ length: 3 }, (_, index) => (
              <div key={index} className="col-md-4 my-sm-3">
                <div className="rounded-5 overflow-hidden custom-blog-cards">
                  <Skeleton height={248} />
                  <div className="py-4">
                    <Skeleton height={30} />
                    <Skeleton height={80} count={1} />
                  </div>
                </div>
              </div>
            ))}
          {!loading &&
            blogs?.slice(0, 3).map((item: any, index: number) => {
              const cardVariants: Variants = {
                hidden: {
                  y: 100,
                },
                visible: {
                  y: 0,
                  transition: {
                    duration: 0.5,
                    delay: 0.2 * (index + 1),
                  },
                },
              };
              return (
                <BlogCard
                  key={index}
                  heading={stripHtmlTags(item?.title)}
                  content={stripHtmlTags(item?.headline)}
                  image={item?.heroImg}
                  slug={item.seoDetails?.slug}
                  cardVariants={cardVariants}
                  author={item?.author}
                  categoryTextName={item?.category?.categoryText}
                />
              );
            })}
        </div>
      )}
      <div className="d-flex justify-content-center my-4">
        <Button
          type="button"
          className="rounded-5 secondary_btn px-5 py-2  bg"
          size="lg"
          onClick={() => router.replace(`/blogs`)}
        >
          {t("All_Blogs")}
        </Button>
      </div>
    </div>
  );
};

export default BlogSection;
