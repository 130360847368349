"use client";
import React, { useEffect, useMemo, useState } from "react";
import { CartItemsCard } from "../common";
import { useSettingsContext } from "@/context";
import Lottie from "lottie-react";
import animationData from "@/utils/animationJson/empty-cart.json";
import CartItemCardSkeleton from "../skeleton/cart-item-card-skeleton";
import Skeleton from "react-loading-skeleton";
import { useTranslations } from "next-intl";

const CartItemsSection = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const { cartItem, liveCurrencyRate, currentCountryCurrency } = useSettingsContext();
  const cartText = useTranslations("cartText")

  useEffect(() => {
    setLoading(false);
  }, []);

  let totalPrice = useMemo(() => {
    let totalPriceIs = 0;
    for (let item of cartItem) {
      totalPriceIs += item?.price * (item?.quantity ?? 1);
    }
    // Convert totalPrice if conversionRate is available
    return currentCountryCurrency!=="SAR" && liveCurrencyRate ? (totalPriceIs * liveCurrencyRate).toFixed(2) : totalPriceIs.toFixed(2);
  }, [cartItem, liveCurrencyRate]);

  return (
    <>
      {!loading ? (
        <>
          {cartItem && cartItem?.length > 0 ? (
            <>
              {cartItem &&
                cartItem?.map((item: any, index: number) => {
                  const getAdjustedPrice = (price: number) =>
                    currentCountryCurrency!=="SAR" && liveCurrencyRate ? (price * liveCurrencyRate).toFixed(2) : price;
                  return (
                    <div
                      key={index}
                      className="my-3 bg-white py-3 align-items-center"
                    >
                      <CartItemsCard
                        heading={item?.title}
                        content={item?.inventory_type}
                        image={item?.media_path}
                        price={`${getAdjustedPrice(item.price)} ${currentCountryCurrency}`}
                        id={item?.id}
                        seller_slug={item?.seller_slug}
                        seller_id={item?.seller_id}
                        rating={item?.avg_rating}
                        supplier_id={item?.supplier_id}
                        product_brand_name={item?.product_brand_name}
                        product_quantity={item?.quantity}
                        businessLogo={item?.business_logo}
                        product_slug={item?.slug}
                        description={item?.description}
                      />
                    </div>
                  );
                })}
              <div className="d-flex justify-content-between align-items-center my-4">
                <div className="fw-bold fs-6 px-3">{cartText("cart_subtotal")}</div>

                <div className="d-flex justify-content-center gap-3 px-5">
                  <div className="fw-light fs-7 text-end">
                    ( {cartText("including_tax_and_shipping")} 
                    {/* <br /> {cartText("for_saudi_arabia")} */}
                    )
                  </div>
                  <div className="fw-bold text-end">
                    {totalPrice ? totalPrice : 0} <br />{" "}
                    <div className="fs-7">{currentCountryCurrency}</div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="d-flex flex-column justify-content-center align-items-center bg-white text-center py-5">
            <img src="../assets/img/empty-cart.webp" style={{maxWidth:"100%"}} width={150} alt="emty-cart"/>
              <h5>{cartText("look_like_your_cart_is_empty")}</h5>
              <p>
                {cartText("please_visit")}
              </p>
            </div>
          )}
        </>
      ):
      (
        <>
        {Array.from({ length: 2 }, (_, index) => {
            return (
              <div
                key={index}
                className="my-3 bg-white py-3 align-items-center"
              >
                <CartItemCardSkeleton />
              </div>
            );
          })}
        <div className="d-flex justify-content-between align-items-center my-4">
          <div className="fw-bold fs-6 px-3">
            <Skeleton width={90}/>
          </div>

          <div className="d-flex justify-content-center gap-3 px-5">
            <div className="fw-light fs-7 text-end">
            <Skeleton width={90} count={2}/>
            </div>
            <div className="fw-bold text-end">
            <Skeleton width={90}/>
              <div className="fs-7">
            <Skeleton width={40}/>
              </div>
            </div>
          </div>
        </div>
      </>
    
      )
      }
    </>
  );
};

export default CartItemsSection;
