'use client'
import { default_supplier_logo } from "@/utils/CountryCodes";
import { useRouter } from "@/navigation";
import { suggestedSellerData } from "@/utils/types";
import { AMAZONE_MEDIA_URL } from "@/utils/constant";

const SuggestedSupplierBuyerCards = ({
  title,
  industry,
  id,
  business_logo,
  slug,
  type
}: suggestedSellerData) => {
  const router = useRouter();
  const handleData = (slug: string) => {
    if (type === "Supplier") {
      router.push(`/business-network/supplier-profile/${slug}`);
    } else {
      router.push(`/business-network/buyer-profile/${slug}`);
    }
  };
  
  return (
      <div
        className="suggested-network-card rounded-4 border bg-white overflow-hidden position-relative my-2 p-2 cursor-pointer"
        key={id}
        onClick={() => handleData(slug || "")}
      >
        <div className="d-flex align-items-center gap-2">
          <img loading="lazy"
            className="rounded-circle border"
            alt={title}
            src={
              business_logo
                ? `${AMAZONE_MEDIA_URL}User/${id}/BusinessLogo/${business_logo}`
                : default_supplier_logo
            }
            width={68}
            height={68}
          />

          <div className="my-2">
            <h4 className="main-heading text-black fs-6 fw-normal">{title}</h4>
            <h6 className="main-heading text-black text-12 fw-light">
              {industry}
            </h6>
          </div>
        </div>
      </div>
  );
};

export default SuggestedSupplierBuyerCards;
