"use client";
import { FC } from "react";
import Image from "next/image";
import { aboutGalleryProps } from "@/utils/types";
import NextImage from "../common/next-image";
type props = {
  galleryData: aboutGalleryProps[];
};

const About: FC<props> = ({ galleryData }) => {
  return (
    <div className="container-fluid p-0 my-4 about-container">
      <div className="clip-box1">
        <div className="content-box1">
          <div className="d-flex w-100 images-container1">
            <div className="col">
              {/* <NextImage
                loading="lazy"
                src={galleryData[0]?.mediaUrl || ""}
                alt="laptop"
                width={800}
                height={500}
                className="mid-image-col1"
                sizes="100vw"
              /> */}
               <img
                loading="lazy"
                src={galleryData[0]?.mediaUrl || ""}
                alt="laptop"
                width={800}
                height={500}
                className="mid-image-col1"
                fetchPriority="high"
              />
            </div>
            <div className="position-absolute">
              <div className="col">
                {/* <NextImage
                  loading="lazy"
                  src={galleryData[1]?.mediaUrl || ""}
                  alt="file"
                  sizes="100vw"
                  width={300}
                  height={230}
                  className="first-image1"
                /> */}
                  <img
                  loading="lazy"
                  src={galleryData[1]?.mediaUrl || ""}
                  alt="file"
                  width={300}
                  height={230}
                  className="first-image1"
                />
              </div>

              <div className="col">
                {/* <NextImage
                  loading="lazy"
                  src={galleryData[2]?.mediaUrl || ""}
                  alt="phone"
                  width={535}
                  sizes="100vw"
                  height={627}
                  className="last-image1"
                /> */}
                   <img
                  loading="lazy"
                  src={galleryData[2]?.mediaUrl || ""}
                  alt="phone"
                  width={535}
                  height={627}
                  className="last-image1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
