"use client";
import {
  AMAZONE_MEDIA_URL,
  ThumbImageRatio,
  defaultproduct,
} from "@/utils/constant";
import { ProductsData } from "@/utils/types";
import { useRouter } from "@/navigation";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

type Props = {
  title: string;
  data: ProductsData[];
};
function ProductBoxCard({ title, data }: Props) {
  const router = useRouter();
  const handleData = (slug: any) => {
    router.replace(`/item-detail/${slug}`);
  };
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (data) {
      setLoading(false);
    }
  }, [data]);
  return (
    <div className="item bg-white box-market-card rounded-3 w-100">
      <p className="mx-3 pt-4">{title}</p>
      <div className="row mx-3">
        {!loading ? 
      (  title === "Public RFP"
          ? data.slice(0, 3).map((item, index) => (
              <div
                key={index}
                className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-6 mb-3"
                onClick={() => handleData(item.slug)}
              >
                <img
                  loading="lazy"
                  className="bg-gray cursor-pointer custom-market-cards"
                  alt={title}
                  src={
                    item.business_logo_path
                      ? AMAZONE_MEDIA_URL +
                        "User/" +
                        item.user_id +
                        "/BusinessLogo/" +
                        item.business_logo_path
                      : defaultproduct
                  }
                  onError={(e) => {
                    e.currentTarget.src = defaultproduct; // Fallback to default image
                  }}
                />
              </div>
            ))
          : data.map((item, index) => (
              <div
                key={index}
                className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-6 mb-3"
                onClick={() => handleData(item.slug)}
              >
                <img
                  loading="lazy"
                  className="bg-gray custom-market-cards cursor-pointer rounded"
                  alt="image"
                  src={
                    item.media_path
                      ? AMAZONE_MEDIA_URL +
                        "User/" +
                        item.supplier_id +
                        "/Product/" +
                        item.id +
                        "/" +
                        ThumbImageRatio +
                        "/" +
                        item.media_path
                      : defaultproduct
                  }
                  onError={(e) => {
                    e.currentTarget.src = defaultproduct; // Fallback to default image
                  }}
                />
              </div>
            ))):
            (
              Array.from({ length: 3 }, (_, index) => (

              <div
              key={index}
              className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-6 mb-3"
            >
              <Skeleton height={90} width={90} borderRadius={10}/>
            </div>
              ))
            )
          
          }
      </div>
    </div>
  );
}

export default ProductBoxCard;
