"use client";
import { FC, useEffect, useState } from "react";
import { ProcurementCard } from "../common";
import {
  ProcurementCardData,
  procurementSectionListProps,
} from "@/utils/types";
import ProcurementCardSkeleton from "../skeleton/procurement-card-skeleton";
import Skeleton from "react-loading-skeleton";

type Props = {
  procurementSectionList: procurementSectionListProps;
};

const ProcurementSection: FC<Props> = ({ procurementSectionList }) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (procurementSectionList) {
      setLoading(false);
    }
  }, [procurementSectionList]);
  return (
    <>
      {
        !loading ? (
          <div className="row gy-4 global-search-container">
            <div className="text-center my-2">
              <h3 className="text-black fs-3">{procurementSectionList?.headline}</h3>
            </div>
            {procurementSectionList?.items.map(
              (item: ProcurementCardData, index: number) => {
                return (
                  <ProcurementCard
                    key={index}
                    heading={item?.title}
                    percentage={item?.headline}
                    content={item?.content}
                  />
                );
              }
            )}
          </div>
        ) : (
          <div className="row gx-4 global-search-container">
            <div className="text-center my-2">
              <h3 className="text-black fs-3"><Skeleton width={190} /></h3>
            </div>
            {Array.from({ length: 4 }, (_, index) => (
              <ProcurementCardSkeleton key={index} />
            ))}
          </div>
        )
      }
    </>

  );
};

export default ProcurementSection;
