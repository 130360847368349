"use client";
import { motion } from "framer-motion";
import { Button } from "@/components/bootstrap";
import { HeroSectionItems, ServiceItemProps } from "@/utils/types";
import {
  ProcurementSoftwareIcon,
  MarketplaceIcon,
  NetworkIcon,
} from "@/utils/icons";
import { Link, useRouter } from "@/navigation";
import NextImage from "./next-image";
import Image from "next/image";

type serviceCardProps = {
  item: HeroSectionItems;
  type: string;
};

function ServiceCard({ item, type }: serviceCardProps) {
  let iconComponent;
  if (item.title === "Procurement Software") {
    iconComponent = <ProcurementSoftwareIcon />;
  } else if (item.title === "B2B Marketplace") {
    iconComponent = <MarketplaceIcon />;
  } else if (item.title === "Business Network") {
    iconComponent = <NetworkIcon />;
  } else {
    iconComponent = <ProcurementSoftwareIcon />;
  }
  const router = useRouter()
  return (
    <div
      className={`${
        type === "primary"
          ? `col-3  position-relative slide-up-box`
          : `col-sm-12 col-lg-3 col-md-4 mb-3 position-relative slide-up-box`
      }`}
    >
      {type === "primary" && (
        <h3
          className="text-center text-white main-heading fs-6 fw-normal m-0 py-3 p-6 text-truncate"
          style={{ background: item.theme.backgroundColor }}
        >
          {item.title}
        </h3>
      )}
      <motion.div
        className={`${
          type === "primary" ? `box-content` : `simple-box-content`
        }`}
        initial={{
          borderBottomColor: item.theme.backgroundColor,
          background: "white",
          padding: "20px",
        }}
        whileHover={{
          borderBottomColor: "white",
          background: item.theme.backgroundColor,
          padding: "20px",
        }}
        transition={{ ease: "linear", duration: 0.8 }}
        onClick={()=>router.push(item?.cta?.redirectionUrl)}
      >
        <h4
          className={`fs-6 fw-normal text-center text-black mb-4`}
        >
          {item.title}
        </h4>
        {/* <NextImage src={item?.imageUrl ?? ""} alt={item?.title} style={{maxWidth:"100%"}} width={item.title === "Business Network" ? 130 : item.title === "B2B Ecommerce Software" ? 90 : 100} height={item.title === "Business Network" ? 100 : item.title === "B2B Ecommerce Software" ? 90 : 100}/> */}
        <img src={item?.imageUrl} alt={item.title} style={{maxWidth:"100%"}} width={`${item.title === "Business Network" ? "130" : item.title === "B2B Ecommerce Software" ? "90" : "100"}`} />
        <h5
          className={`fs-6 fw-bold text-center text-black my-4`}
          style={{height:"1.3rem"}}
        >
          {item.headline}
        </h5>
        <p className={`tag text-center m-0 text-black`}>{item.content}</p>

        <Link
          className="btn btn-md bg-light rounded-5 fw-semibold my-3"
          style={{ color: item.theme.backgroundColor }}
          href={item?.cta?.redirectionUrl}
        >
          {item?.cta?.label}
        </Link>
      </motion.div>
    </div>
  );
}

export default ServiceCard;
