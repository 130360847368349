"use client";
import { useRouter } from "@/navigation";
import { default_supplier_logo } from "@/utils/CountryCodes";
import { AMAZONE_MEDIA_URL } from "@/utils/constant";
import { IconCardProps } from "@/utils/types";

function IconCard({ id, icon, title, time, page, seller_slug }: IconCardProps) {
  const router = useRouter();

  return (
    <div
      className={`cursor-pointer ${
        page == "network-page"
          ? "align-items-center d-flex bg-white shadow gap-2 rounded-4 icon-mobile-card p-3"
          : "align-items-center d-flex gap-lg-2 mx-lg-2 mx-1"
      }`}
      onClick={()=>router.push(`/business-network/supplier-profile/${seller_slug}`)}
    >
      {page == "network-page" || page == "marketplace-page" ? (
        <>
          <img
            className={`${
              page == "network-page" || page == "marketplace-page"
                ? "rounded-1"
                : "rounded-circle border"
            }`}
            alt={title}
            src={
              icon
                ? `${AMAZONE_MEDIA_URL}User/${id}/BusinessLogo/${icon}`
                : default_supplier_logo
            }
            width={48}
            height={48}
            loading="lazy"
          />
        </>
      ) : (
        <img
          className="rounded-circle border"
          alt="image"
          src={icon}
          width={48}
          height={48}
          loading="lazy"
        />
      )}
      <p className="fs-6 fw-bold m-0 ms-1 text-truncate">
        {title} <br />
        <span className="fs-6 fw-light">{time}</span>
      </p>
    </div>
  );
}

export default IconCard;
