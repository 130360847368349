"use client";
import React, { useEffect, useState } from "react";
import { default_supplier_logo } from "@/utils/CountryCodes";
import { AMAZONE_MEDIA_URL } from "@/utils/constant";
import { EmailOpenIcon, GlobeIcon, PhoneIcon } from "@/utils/icons";
import ShowAddressModal from "./show-address-modal";
import Skeleton from "react-loading-skeleton";
import { useSettingsContext } from "@/context";

const SupplierDetailsCard = ({ data }: any) => {
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const {onUpdate} = useSettingsContext();
  useEffect(() => {
    if (data) {
      setLoading(false);
    }
  }, [data]);
  const handleIconClick = () => {
    onUpdate("redirectionUrl", {
      path: "create RFI for supplier",
      id: data?.user_id || ""
    });
    setModalShow(true);
  };

  return (
    <>
      {!loading ? (
        <div className="rounded-5 bg-white p-4">
          <img
            loading="lazy"
            className="rounded-circle"
            alt={data.business_name}
            src={
              data.business_logo_path
                ? AMAZONE_MEDIA_URL +
                  "User/" +
                  data.user_id +
                  "/BusinessLogo/" +
                  data.business_logo_path
                : default_supplier_logo
            }
            width={84}
            height={84}
          />
          <p className="fw-bold fs-5 m-0 my-3 supplier-content-color">
            {data.business_name}
          </p>
          <p className="fw-normal fs-7 p-2 border rounded-2 text-center supplier-content-color">
            {data.industry_name}
          </p>
          <p className="fw-light fs-7 m-0 my-3 supplier-content-color">
            {data.business_address}
          </p>
          <div className="d-flex justify-content-between w-85 m-auto">
            <div
              className="d-flex flex-column align-items-center cursor-pointer"
              onClick={handleIconClick}
            >
              <div className="bg-white rounded-circle border suppliers-circle-container 123">
                <PhoneIcon />
              </div>
              <p className="fs-7 fw-light my-sm-2 text-center">
                Call <br /> Now
              </p>
            </div>
            <div
              className="d-flex flex-column align-items-center cursor-pointer"
              onClick={handleIconClick}
            >
              <div className="bg-white rounded-circle border suppliers-circle-container">
                <EmailOpenIcon />
              </div>
              <p className="fs-7 fw-light my-sm-2 text-center">
                Email <br /> Now
              </p>
            </div>
            <div
              className="d-flex flex-column align-items-center cursor-pointer"
              onClick={handleIconClick}
            >
              <div className="bg-white rounded-circle border suppliers-circle-container">
                <GlobeIcon />
              </div>
              <p className="fs-7 fw-light my-sm-2 text-center">
                Visit <br /> Website
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="rounded-5 bg-white p-4">
          <Skeleton height={40} width={100} borderRadius={5} />
          <p className="fw-bold fs-5 m-0 my-3 supplier-content-color">
            <Skeleton />
          </p>
          <Skeleton height={70} borderRadius={5} />
          <p className="fw-light fs-7 m-0 my-3 supplier-content-color">
            <Skeleton />
          </p>
          <div className="d-flex justify-content-between w-85 m-auto">
            <div
              className="d-flex flex-column align-items-center cursor-pointer"
              onClick={handleIconClick}
            >
              <Skeleton height={40} width={40} borderRadius={50} />

              <Skeleton count={2} width={20} height={10} />
            </div>
            <div
              className="d-flex flex-column align-items-center cursor-pointer"
              onClick={handleIconClick}
            >
              <Skeleton height={40} width={40} borderRadius={50} />

              <Skeleton count={2} width={20} height={10} />
            </div>
            <div
              className="d-flex flex-column align-items-center cursor-pointer"
              onClick={handleIconClick}
            >
              <Skeleton height={40} width={40} borderRadius={50} />

              <Skeleton count={2} width={20} height={10} />
            </div>
          </div>
        </div>
      )}
          <ShowAddressModal
            type="supplier"
            show={modalShow}
            onHide={() => setModalShow(false)}
            data={data}
          />
    </>
  );
};

export default SupplierDetailsCard;
