"use client";
import { factNumbersItem } from "@/utils/types";
import CharacterAnimation from "./character-animation";
import NextImage from "./next-image";
import Image from "next/image";

type Props = {
  item: factNumbersItem;
  isMobile?: boolean;
};

function FactsNumbersCard({ item, isMobile }: Props) {
  return (
    <div
      className={`p-2 p-md-4 d-flex flex-column justify-content-around rounded-4`}
      style={{ background: item?.theme?.backgroundColor }}
    >
      {!!item.imageUrl && (
        <div className="box-image text-center">
          <img
            src={item.imageUrl}
            alt={item?.title}
            height="266px"
            width="232px"
            loading="lazy"
          />
        </div>
      )}
      <div className="box-content">
        {isMobile ? (
          <h4>{item?.title}</h4>
        ) : (
          <CharacterAnimation
            className="text-black fw-bold fs-2"
            title={item?.title}
          />
        )}

        <h5 className="text-black fw-normal fs-6">{item.headline}</h5>
        {!!item.content && (
          <p className="text-black fs-5 fw-bold m-0">{item.content}</p>
        )}
      </div>
    </div>
  );
}

export default FactsNumbersCard;
