"use client";
import { Button } from "@/components/bootstrap";
import Slider from "@/components/slick";
import { FC, useEffect, useState } from "react";
import { useRef } from "react";
import { MarketplaceImageSectionDataProps } from "@/utils/types";
import Skeleton from "react-loading-skeleton";
import { Link } from "@/navigation";
import { useSettingsContext } from "@/context";

const MarketplaceImageSection: FC<any> = ({ bannerData }) => {
  const { currentLocale } = useSettingsContext();
  const sliderRef = useRef(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (bannerData) {
      setLoading(false);
    }
  }, [bannerData]);

  const sliderSettings = {
    className: "center",
    arrows: false,
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 1,
    speed: 500,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          centerPadding: "0",
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          centerPadding: "0",
        },
      },
    ],
  };
  
  return (
    <Slider ref={sliderRef} {...sliderSettings}>
      {!loading ? (bannerData?.items.map((item: MarketplaceImageSectionDataProps) => (
        <div className="" key={item.id}>
          <div
            className={`container-fluid p-4 p-md-11 text-white rounded-5 marketplace-top-banner  ${currentLocale === "en" ? "text-start" : "text-end"}`}
            style={{
              backgroundImage: item.imageUrl ? `url(${item.imageUrl})` : "none",
            }}
          >
            <h3 className="pr-10">{item.title}</h3>
            <h3 className="pr-10">{item.headline}</h3>
            <Link
              href="/get-started"
              className="text-white rounded-5 border white_btn my-3 py-3 px-5 d-inline-block"
            >
              {item.cta?.label}
            </Link>
          </div>
        </div>
      ))):
      (
        Array.from({ length: 4 }, (_, index) => (

        <div className="" key={index}>
       <Skeleton height={400} borderRadius={20}/>
        </div>
        ))
      )
      }
    </Slider>
  );
};

export default MarketplaceImageSection;
