"use client";
import { FC } from "react";
import { Button } from "@/components/bootstrap";
import { QuickTourProps } from "@/utils/types";
import { Link } from "@/navigation";
import Reveal from "../common/Reveal";

const QuickTour: FC<QuickTourProps | any> = ({ tourData }) => {
  return (
    <Reveal width="100%">
    <div className="home-quick-tour container-fluid p-0 ">
      <div className="clip-box"></div>
      <div className="content-box mb-5">
        <div className="row w-100 images-container">
          <div className="col position-relative first-image-col">
            <img
              src={tourData?.items[0].mediaUrl}
              alt="BuyerDshboard"
              style={{
                width: "100%",
                height: "100%",
              }}
              className="first-image rounded-2"
              loading="lazy"
            />
          </div>
          <div className="col position-relative mid-image-col">
            <img
              src={tourData?.items[1].mediaUrl}
              alt="Getintouch"
              style={{
                width: "100%",
                height: "100%",
              }}
              loading="lazy"
              className="mid-image rounded-2"
            />
          </div>
          <div className="col position-relative last-image-col">
            <img
              src={tourData?.items[2].mediaUrl}
              alt="Blogs"
              style={{
                width: "100%",
                height: "100%",
              }}
              className="last-image rounded-2"
              loading="lazy"
            />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center my-3">
        <Link
          href={tourData?.cta?.redirectionUrl}
          type="button"
          className="rounded-5 secondary_btn text-white py-3 px-5 fw-bold text-decoration-none"
        >
          {tourData?.cta?.label}
        </Link>
      </div>
    </div>
    </Reveal>
  );
};

export default QuickTour;
