"use client";
import { ProductImgProps } from "@/utils/types";
import { motion } from "framer-motion";
import {
  AMAZONE_MEDIA_URL,
  ThumbImageRatio,
  defaultproduct,
} from "@/utils/constant";

function ProductsSubCards({
  image,
  cardVariants,
  supplier_id,
  id,
  fetchImagePath,
}: ProductImgProps) {
  return (
    <motion.div className=" my-sm-2 subimg--card" variants={cardVariants}>
      <div
        className="overflow-hidden img--alignment cursor-pointer"
        onClick={() =>
          fetchImagePath(
            image
              ? AMAZONE_MEDIA_URL +
                  "User/" +
                  supplier_id +
                  "/Product/" +
                  id +
                  "/" +
                  image
              : defaultproduct
          )
        }
      >
        <img
          loading="lazy"
          alt="product_image"
          src={
            image
              ? AMAZONE_MEDIA_URL +
                "User/" +
                supplier_id +
                "/Product/" +
                id +
                "/" +
                ThumbImageRatio +
                "/" +
                image
              : defaultproduct
          }
          onError={(e) => {
            e.currentTarget.src = defaultproduct; // Fallback to default image
          }}
          className="subCardImg cursor-pointer"
        />
      </div>
    </motion.div>
  );
}

export default ProductsSubCards;
