'use client'
import { FC, useEffect, useState } from "react";
import { PopularSupplierCard } from "../common";
import { popularSupplierBuyer } from "@/utils/types";
import Slider from "@/components/slick";
import PopularSupplierCardSkeleton from "../skeleton/popular-supplier-card-skeleton";

type props = {
  data: popularSupplierBuyer[];
  type?: string;
};

const PopularSupplierSection: FC<props> = ({ data, type }) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (data) {
      setLoading(false);
    }
  }, [data]);
  
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 4,
    speed: 500,
    arrows: false,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "0",
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          centerMode: false,
          centerPadding: "0",
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          centerMode: false,
          centerPadding: "0",
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {!loading ? (data &&
        data.map((item: popularSupplierBuyer, index: number) => {
          return (
            <PopularSupplierCard
              key={index}
              user_id={item.user_id}
              icon={item.business_logo}
              title={item.industry_name}
              name={item.business_name}
              slug={item.slug}
              type={type}
            />
          );
        })):
        (
          Array.from({ length: 10 }, (_, index) => (
            <PopularSupplierCardSkeleton key={index} />
          ))
        )
        }
    </Slider>
  );
};

export default PopularSupplierSection;
