"use client";
import React, { useMemo, useState } from "react";
import Slider from "react-slick";
import ServiceSliderCard from "@/components/common/service-slider-card";
import { useClientMediaQuery } from "@/hooks/use-client-media-query";

function ServicesSlider({ serviceSectionData, locale }: any) {
  const isMobile = useClientMediaQuery("(max-width: 768px)");
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = useMemo(() => {
    return {
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      customPaging: (i: number) => (
        <div
          style={{
            // width: "140px",
            height: "100%",
            color: "#272727",
            borderBottom: `7px ${currentSlide === i
              ? serviceSectionData.items[i].theme.contentColor
              : serviceSectionData.items[i].theme.backgroundColor
              } solid`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "14px",
            fontWeight: 400,
          }}
          className="custom-paging"
        >
          {serviceSectionData.items[i].title}
        </div>
      ),
      afterChange: (current: any) => {
        setCurrentSlide(current);
      },
    };
  }, [currentSlide]);

  const mobileSettings = useMemo(() => {
    return {
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      afterChange: (current: any) => {
        setCurrentSlide(current);
      },
    };
  }, [currentSlide, serviceSectionData.items]);

  return (
    <div
      className="w-100 services-slider-container"
      style={isMobile ? { minHeight: "750px" } : { maxHeight: "600px" }}
    >
      {isMobile ? (
        <Slider {...mobileSettings}>
          {serviceSectionData.items.map((item: any, index: any) => {
            return (
              <React.Fragment key={index}>
                <div
                  style={{
                    textAlign: "center",
                    paddingBottom: "20px",
                    borderBottom: `7px ${currentSlide === item
                      ? item.theme.backgroundColor
                      : item.theme.contentColor
                      } solid`,
                  }}
                >
                  {item?.title}
                </div>
                <ServiceSliderCard item={item} locale={locale} />
              </React.Fragment>
            );
          })}
        </Slider>
      ) : (
        <Slider {...settings}>
          {serviceSectionData.items.map((item: any, index: any) => {
            return <ServiceSliderCard key={index} item={item} locale={locale} />;
          })}
        </Slider>
      )}
    </div>
  );
}

export default ServicesSlider;
