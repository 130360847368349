"use client";
import React, { useEffect, useState } from "react";
import { BlogEprocureSection } from "@/components/widgets";
import { Dropdown } from "@/components/bootstrap";
import { useClientMediaQuery } from "@/hooks/use-client-media-query";
import Skeleton from "react-loading-skeleton";
import { useTranslations } from "next-intl";
import { useRouter } from "@/navigation";
import { FaAngleDown } from "react-icons/fa6";

const BlogsPage = ({
  blogCategoriesData,
  blogsData: initialBlogsData,
  categoryText
}: any) => {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useClientMediaQuery("(max-width: 768px)");
  const [loading, setLoading] = useState(true);
  const [filteredBlogsData,setFilteredBlogsData] = useState([]);
  useEffect(() => {
    if (blogCategoriesData) {
      setLoading(false);
    }
  }, [blogCategoriesData]);
  
  const handleCategoryClick = (categoryId: string) => {
    const slugRoutes = categoryId==="all" ? "" : categoryId==="News" ? "news" : categoryId==="Education" ? "education" : categoryId==="Solution" ? "solution" : categoryId==="Service" ? "service" : categoryId==="Case Studies" ? "case-studies" : "blog" ; 
    window.location.assign(`/blogs/${slugRoutes}`);
  };
  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };
  const t = useTranslations("Index")

  useEffect(()=>{
    if(categoryText === "all"){
      setFilteredBlogsData(initialBlogsData)
    }else{
      setFilteredBlogsData(initialBlogsData && initialBlogsData?.length>0 && initialBlogsData.filter(
          (blog: any) =>blog?.category?.categoryText === categoryText 
        )
      )
    }
  },[categoryText,initialBlogsData])
  return (
    <>
      <div className="position-relative mt-5">
        {!isMobile && (
          <div className="position-absolute top-0 start-50 translate-middle w-100 px-3 px-md-5">
            <ul className="nav justify-content-center shadow rounded-5 p-2 bg-white my-5 nav-tabs-scroll">
              <li
                className="nav-item cursor-pointer"
              >
                <a
                  className={`nav-link ${
                  categoryText === "all" ? "text-primary fw-bold" : ""
                }`}
                  onClick={() => handleCategoryClick("all")}
                >
                  {/* {t('All_Blogs')} */}
                  All Blogs
                </a>
              </li>
              {!loading ? blogCategoriesData &&
                blogCategoriesData.map((item: any, index: any) => (
                  <li
                    className="nav-item cursor-pointer"
                    key={index}
                  >
                    <a
                      className={`nav-link  ${
                      categoryText === item.categoryText ? "text-primary fw-bold" : ""
                    }`}
                      onClick={() => handleCategoryClick(item.categoryText)}
                    >
                      {item.categoryText}
                    </a>
                  </li>
                ))
                :
                (
                  Array.from({ length: 11 }, (_, index) => (
                    <li
                      className="nav-item cursor-pointer"
                      key={index}
                    >
                      <Skeleton borderRadius={5} height={20} width={80} className="mx-2 mt-3" />
                    </li>
                  ))
                )
              }
            </ul>
          </div>
        )}
      </div>
      <div className="container-fluid pt-md-5 text-center">
        {isMobile && (
          <div className="d-flex justify-content-center py-4 ">
            <Dropdown
              show={isOpen}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className="outline-dark cursor-pointer"
            >
              <Dropdown.Toggle
                role="button"
                variant=""
                id="dropdown-basic"
                as="span"
                className="cursor-pointer text-black text-capitalize shadow"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                aria-expanded={isOpen}
                aria-haspopup="true"
                placeholder="dropdown"
                style={{
                  background: "white",
                  padding: "20px 60px",
                  borderRadius: "10px!important",
                  display: "flex",
                  justifyItems: "center",
                  boxShadow: "0 0 17px 3px #eee!important"
                }}
              >
                {categoryText}<span className="ps-1"><FaAngleDown size={12}/></span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="custom-blog-dropdown-menu">
                <Dropdown.Item onClick={() => handleCategoryClick("all")}>
                  All Blogs
                </Dropdown.Item>
                {blogCategoriesData &&
                  blogCategoriesData.map((item: any, index: any) => (
                    <Dropdown.Item
                      onClick={() => handleCategoryClick(item.categoryText)}
                      key={index}
                    >
                      {" "}
                      {item.categoryText}
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
        <BlogEprocureSection data={filteredBlogsData} categoryText={categoryText} />
      </div>
    </>
  );
};

export default BlogsPage;
