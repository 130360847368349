'use client'
import { IconCard } from "@/components/common";
import { useEffect, useState } from "react";
import IconCardSkeleton from "../skeleton/icon-card-skeleton";

function SupplierCompaniesCard({ title, supplierData }: any) {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (supplierData) {
      setLoading(false);
    }
  }, [supplierData]);
  return (
    <div>
      <h3 className="main-heading text-black fs-5 fw-bold my-sm-4 px-md-0 px-2 bn-section-m">
        {title}
      </h3>
      <div className="row">
        {!loading ? (supplierData &&
          supplierData.slice(0, 6).map((item: any) => (
            <div className="col-md-4 col-sm-6 my-2" key={item.supplier_id}>
              <IconCard
                id={item.supplier_id}
                icon={item.business_logo}
                title={item.supplier_name}
                time={item.industry_name}
                seller_slug={item.slug}
                page={"network-page"}
              />
            </div>
          ))):
          (
            Array.from({ length: 6 }, (_, index) => (
            <div className="col-md-4 col-sm-6 my-2" key={index}>
              <IconCardSkeleton />
              </div>
            ))
          )
          }
      </div>
    </div>
  );
}

export default SupplierCompaniesCard;
