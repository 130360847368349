"use client";
import { motion } from "framer-motion";
import { useRouter } from "@/navigation";
import { BlogCardProps } from "@/utils/types";
import { useClientMediaQuery } from "@/hooks/use-client-media-query";
import { FaArrowRight,FaArrowLeft } from "react-icons/fa";
import { useSettingsContext } from "@/context";

function BlogCard({
  heading,
  content,
  image,
  slug,
  cardVariants,
  categoryTextName,
  author,
}: BlogCardProps) {
  const router = useRouter();
  const isMobile = useClientMediaQuery("(max-width: 768px)");
  const slugRoutes = categoryTextName==="all" ? "blog" : categoryTextName==="News" ? "news" : categoryTextName==="Education" ? "education" : categoryTextName==="Solution" ? "solution" : categoryTextName==="Service" ? "service" : categoryTextName==="Case Studies" ? "case-studies" : "blog" ; 
  const handleData = (slug: any) => {
    const encrypt = encodeURIComponent(slug);
    router.replace(`/blogs/${slugRoutes}/${encrypt}`);
  };
  const { themeDirection } =
  useSettingsContext();
  const dateFun = (createdAt: any) => {
    const date = new Date(createdAt);
    const formattedDate = date.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });
    return formattedDate;
  };
  return (
    <motion.div
      className="col-lg-4 col-md-6 my-3 d-flex justify-content-center"
      variants={cardVariants}
    >
      <div onClick={() => handleData(slug)} className="cursor-pointer">
        <div className={`rounded-5 overflow-hidden custom-blog-cards ${isMobile ? "shadow-sm" : "shadow"}`}>
          {image ? (
            <img
              alt={heading}
              src={image}
              width={377}
              height={180}
              className="w-100"
              fetchPriority="high"
            />
          ) : (
            <img
              alt="placeholder-image"
              src="/assets/img/elementor-placeholder-image.webp"
              width={377}
              height={180}
              className="w-100"
              fetchPriority="high"
            />
          )}
        <div className="px-4 mt-3 text-start">
        <span className={`blog-pill ${themeDirection==="rtl" && "pt-1"}`}>{categoryTextName}</span>
        </div>
          <h4
            className="main-heading text-start text-black mt-2 fs-6 fw-normal px-4 "
            style={{ minHeight: "40px" }}
          >
            {heading}
          </h4>
          <p className="fw-light m-0 px-4 pb-2 text-start" style={{ minHeight: "80px" }}>
            {content.length > 125 ? content.substring(0, 125) + "..." : content}
          </p>

          <div className="px-4 d-flex align-items-center mb-4 justify-content-between">
          <img
          className="rounded-circle border"
          alt="image"
          src={author?.profileImg}
          width={40}
          height={40}
          loading="lazy"
        />
      <p className="text-14 fw-bold m-0 ms-1 text-truncate text-start">
        {author?.firstName + " " + author.lastName} <br />
        <span className="text-14 fw-light">{(dateFun(author?.createdAt))}</span>
      </p>
      <button className="arrow-btn">
       {themeDirection==='rtl' ? <FaArrowLeft /> :  <FaArrowRight />}
      </button>
        </div>
        </div>
      </div>
    </motion.div>
  );
}

export default BlogCard;
