"use client";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { Variants } from "framer-motion";
import Slider from "@/components/slick";
import { ProductCard } from "../common";
import { ProductsData } from "@/utils/types";
import { useLocalStorage } from "@/hooks/use-local-storage";
import ProductSliderSkeleton from "../skeleton/product-slider-skeleton";
import { useSettingsContext } from "@/context";

const ProductListSlider: FC<{
  catId: number;
  popularProduct: ProductsData[];
  message?: string;
}> = ({ popularProduct }) => {
  const sliderRef = useRef(null);
  const [viewedProducts, setViewedProducts] = useLocalStorage("recentData", []);
  const [isLoading, setIsLoading] = useState(true);
  const {liveCurrencyRate,currentCountryCurrency} = useSettingsContext()
  useEffect(() => {
    if (popularProduct) {
      setIsLoading(false);
    }
  }, [popularProduct]);
  // Handle viewing recent products
  const handleRecentView = (item: ProductsData) => {
    if (!viewedProducts.some((product: ProductsData) => product.id === item.id)) {
      setViewedProducts([item, ...viewedProducts]);
    }
  };

  // Slider settings
  const sliderSettings = useMemo(() => ({
    arrows: false,
    dots: false,
    infinite: popularProduct?.length > 4,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "0px",
        },
      },
    ],
  }), [popularProduct]);

  // Card animation variants
  const createCardVariants = (index: number): Variants => ({
    hidden: { y: 100 },
    visible: {
      y: 0,
      transition: { duration: 0.5, delay: 0.2 * (index + 1) },
    },
  });

  // Adjust price based on conversion rate
  const getAdjustedPrice = (price: number) =>
    currentCountryCurrency!=="SAR" && liveCurrencyRate ? (price * liveCurrencyRate).toFixed(2) : price;

  // Conditional rendering for product cards or skeletons
  const renderProductCards = () =>
    popularProduct?.map((item: ProductsData, index: number) => (
      <div key={item.id} className="my-3" onClick={() => handleRecentView(item)}>
        <ProductCard
          heading={item?.title}
          content={item?.inventory_type}
          image={item?.media_path}
          price={`${getAdjustedPrice(item.price)} ${currentCountryCurrency}`}
          cardVariants={createCardVariants(index)}
          id={item?.id}
          rating={item?.total_reviews}
          supplier_id={item?.supplier_id}
          slug={item?.slug}
          inventoryType={item?.inventory_type}
          businessLogo={item?.business_logo}
          description={item?.description}
        />
      </div>
    ));

  return (
    <Slider ref={sliderRef} {...sliderSettings}>
      {isLoading
        ? Array.from({ length: 10 }, (_, index) => <ProductSliderSkeleton key={index} />)
        : renderProductCards()}
    </Slider>
  );
};

export default ProductListSlider;
