"use client";
import { FC, useEffect, useState } from "react";
import { mapStylesContactDark } from "@/utils/data";
import { GoogleMap, OverlayView, useJsApiLoader } from "@react-google-maps/api";
import { useClientMediaQuery } from "@/hooks/use-client-media-query";
import { HiBuildingOffice,HiPhone } from "react-icons/hi2";
import { HiMail } from "react-icons/hi";
import { useSettingsContext } from "@/context";

const ContactMaskGroup: FC<any> = ({ data }) => {
  const { currentLocale } = useSettingsContext();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const isMobile = useClientMediaQuery("(max-width: 768px)");

  const { isLoaded: apiLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAVo6ysFs5z0UEVVPtLns6lr3DCi6ZTXWc",
  });

  useEffect(() => {
    if (apiLoaded) {
      setIsLoaded(true);
    }
  }, [apiLoaded]);

  // Example locations for the buildings
  const buildings = [
    {
      name: "e-Procure HQ (Riyadh, KSA)",
      position: { lat: 24.726107, lng: 46.689287 },
    },
    {
      name: "e-Procure regional (Dubai, UAE)",
      position: { lat: 25.116973, lng: 55.196714 },
    },
  ];

  return (
    <div className="bg-white z-1 position-relative">
      <div className="row ps-2">
      <div className="col-md-4 p-4">
      <div className="contact-info">
          <div className="contact-info-item">
            <div className="contact-info-icon shadow d-flex align-items-center justify-content-center" style={{width:"115px!important"}}>
            <HiBuildingOffice size={25} className="" />
            </div>
            
            <div className={`contact-info-content ${currentLocale==="en" ? "ms-4" : "me-4"}`}>
              <h4>Address</h4>
              <p>Roshin Business Front,<br/> Airport Road Building S4, <br/>Ground Floor,
              Levels 1 & 2 Riyadh, KSA, 13413 - 3667</p>
            </div>
          </div>
          
          <div className="contact-info-item">
            <div className="contact-info-icon shadow d-flex align-items-center justify-content-center">
            <HiPhone size={25} className="" />
            </div>
            
            <div className={`contact-info-content ${currentLocale==="en" ? "ms-4" : "me-4"}`}>
              <h4>Phone</h4>
              <p>+966 11 8274184</p>
            </div>
          </div>
          
          <div className="contact-info-item">
            <div className="contact-info-icon shadow d-flex align-items-center justify-content-center">
            <HiMail size={25} className="" />
            </div>
            
            <div className={`contact-info-content ${currentLocale==="en" ? "ms-4" : "me-4"}`}>
              <h4>Email</h4>
             <p>hello@e-procure.net</p>
            </div>
          </div>
        </div>
        </div>
        <div className="col-md-8 p-0">
          {isLoaded && (
            <GoogleMap
              mapContainerStyle={{ width: "100%", height: "400px" }} // Ensure proper height and width
              center={{ lat: 24.726107, lng: 46.689287 }} // Centered on the first building
              zoom={6}
              options={{
                styles: mapStylesContactDark,
              }}
            >
              {buildings.map((building, index) => (
                <OverlayView
                  key={index}
                  position={building.position}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                >
                  <div
                    style={{
                      background: "#fecaca",
                      padding: "5px 10px",
                      color: "#ef4444",
                      width: "max-content",
                      borderRadius: "4px",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    {building.name}
                  </div>
                </OverlayView>
              ))}
            </GoogleMap>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactMaskGroup;
