import React from "react";
import { Button } from "@/components/bootstrap";
import { Link } from "@/navigation";
import NextImage from "./next-image";

export default function ServiceSliderCard({ item, locale }: any) {
  return (
    <div className="bg-gray">
      <div className="row slider-services ">
        <div className="col-md-6 d-flex align-items-center justify-content-center">
          {/* <NextImage
            src={item?.imageUrl}
            alt="slider-img"
            className="img-fluid"
            height={745}
            width={431}
            sizes="100vw"
            loading="lazy"
          /> */}
            <img
            src={item?.imageUrl}
            alt={item?.headline}
            className="img-fluid"
            height="745px"
            width="431px"
            loading="lazy"
          />
        </div>
        <div className={`col-md-6 px-5 service-slider-content  ${locale === 'en' ? "text-start" : "text-end"}`}>
          <h3 className="text-black fs-5">{item?.headline}</h3>
          <p className="text-black my-3 fs-6 fw-light">{item?.content}</p>
          <Link
            href={item?.cta?.redirectionUrl}
            className="text-white rounded-5 secondary_btn py-3 px-5 d-inline-block"
          >
            {item?.cta?.label}
          </Link>
        </div>
      </div>
    </div>
  );
}
