"use client";
import { AndroidIcon, AppleIcon } from "@/utils/icons";
import {Link} from "@/navigation";
import React from "react";
import { useTranslations } from "next-intl";

function AppDownloadSection({ data }: any) {
  const footerText = useTranslations("footerText")
  return (
    <div className="row my-2 my-md-0">
      <div className="col-md-6 col-lg-4 col-6 py-1 ml-2">
        <div className="mobile-store-card">
          <Link
            href="https://apps.apple.com/us/app/e-procure/id1465201539?ls=1"
            target="_blank"
            className=" nav-link d-flex align-items-center cursor-pointer store-cards p-2 gap-2"
            rel="noopener noreferrer"
          >
            <AppleIcon />
            <div className="d-flex flex-column">
              <span className="content">{footerText("get_it_on")}</span>
              <span className="stores">Apple Store</span>
            </div>
          </Link>
        </div>
      </div>
      <div className="col-md-6 col-lg-4 col-6 py-1 ml-2">
        <div className="mobile-store-card">
          <Link
            href="https://play.google.com/store/apps/details?id=com.eprocure.buyer"
            target="_blank"
            className="nav-link d-flex align-items-center cursor-pointer store-cards p-2 gap-2"
            rel="noopener noreferrer"
          >
            <AndroidIcon />
            <div className="d-flex flex-column">
              <span className="content">{footerText("get_it_on")}</span>
              <span className="stores">Google Play</span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default AppDownloadSection;
